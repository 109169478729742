import { Theme } from "@mui/material/styles";
import { CSSProperties } from "react";
import { useTheme } from "react-admin";

type CSSDef = Record<string, CSSProperties>
type CSSPropertiesDef = Record<string, CSSProperties | CSSDef>

type UseStylesFunction = (theme: Theme) => CSSPropertiesDef
export function makeStyles(func: UseStylesFunction): () => CSSPropertiesDef {
  return function useStyles() {
    const [theme] = useTheme()
    return func(theme as Theme);
  }  
}