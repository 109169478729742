import React from "react"
import { Datagrid, TextField, EditButton, DateField, ListProps, ArrayField, ChipField, SingleFieldList } from "ra-ui-materialui"
import { List } from "../../components/DefaultDetails"

export default function AdminList(props: ListProps) {
  return <List {...props}  hasCreate>
    <Datagrid >
      <TextField source="email" />
      <TextField source="name" />
      <DateField source="createdAt" />
      <ArrayField source="roles">
        <SingleFieldList>
          <ChipField source="role" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
}