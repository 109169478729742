import { useState, useCallback, useEffect, useRef, useLayoutEffect } from "react"
import { isEqual, every, isUndefined, values, includes } from "lodash"
import { useAuthProvider, usePermissions } from "react-admin";
import { Profile } from "../lib/authProvider";
import storage from "../lib/storage";
import { getApplicationUuids } from "../lib/helper";
import { Application } from "../lib/types";
export function useAccount(): Profile {
  const authProvider = useAuthProvider()
  const [account, setAccount] = useState(authProvider.getProfile())
  const updateState = useCallback(() => setAccount(authProvider.getProfile()), [authProvider, setAccount])

  useEffect(() => {
    authProvider.addListener("profiles", updateState)
    return () => authProvider.removeListener("profiles", updateState)
  }, [authProvider, updateState])

  return account
}
export function useProfiles(): Profile[]{
  const authProvider = useAuthProvider()
  const [profiles, setProfiles] = useState(authProvider.getProfiles())

  useEffect(() => {
    authProvider.addListener("profiles", setProfiles)
    return () => authProvider.removeListener("profiles", setProfiles)
  }, [authProvider, setProfiles])

  return profiles
}

export function useStateToggle(defaultValue: boolean): [boolean, () => void] {
  const [value, setValue] = useState(defaultValue)
  const toggle = useCallback(() => setValue(!value), [value, setValue])
  return [value, toggle]
}

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value;
  })
  return ref.current;
}

export function useLoadingKey(deps: any) {
  const oldDeps = usePrevious(deps)
  const [key, setKey] = useState("loading")
  useEffect(() => {
    if (!isEqual(oldDeps, deps) && every(deps, dep => !isUndefined(dep))) {
      setKey("loaded")
    }
  }, [oldDeps, deps, setKey])

  return key
}

export function usePageTitle(title: string) {
  useLayoutEffect(() => {
    const urlTitle = (new URL(document.location.href)).searchParams.get("title")
    if (title) document.title = title
    if (urlTitle) document.title = urlTitle
  }, [title])
}

export function useFilteredApplications(role: string): Application[] {
  const applications: Application[] = values(storage.getObject("applications"))
  const { permissions } = usePermissions()
  const uuids = getApplicationUuids(permissions, role)
  return uuids ? applications.filter(app => includes(uuids, app.uuid)) : applications
}