import {  styled } from "@mui/material";
import { Button } from "@mui/material";
import { Portal } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import HarwareState from "./components/HardwareState";
import SystemOptions from "./components/SystemOptions";
import Restrictions from "./components/Restrictions";
import { MutableRefObject, RefObject } from "react";
import { Socket } from "socket.io-client";
import { MonitoringConfigRestriction, MonitoringHardwareState, MonitoringSystemState } from "../../../../lib/types";

const SystemRoot = styled("div")({
  padding: 10,
  width: "100%",
  maxWidth: "100%",
  minWidth: 400,
  display: "flex",
  flexDirection: "row",
})

interface SystemConfigProps {
  systemState?: MonitoringSystemState, 
  hardwareState?: MonitoringHardwareState, 
  restrictions?: MonitoringConfigRestriction, 
  socketRef: MutableRefObject<Socket | undefined>,
  actionsContainer: RefObject<HTMLSpanElement | null>, 
  visible: boolean
  readonly?: boolean
}


export default function SystemConfig({ systemState, hardwareState, restrictions, socketRef, actionsContainer, visible, readonly = false }: SystemConfigProps) {
  const onRestart =  () => socketRef.current?.emit("restart", "icacs")
  const onReboot = () => socketRef.current?.emit("reboot")
 
  return <SystemRoot >
    {actionsContainer.current && !readonly && <Portal container={actionsContainer.current}  >
      {visible && <Button variant="outlined" color="primary" startIcon={<Refresh />} onClick={onRestart} >Restart ICACS</Button>}
      {visible && <Button variant="outlined" color="primary" startIcon={<Refresh />} onClick={onReboot} >Reboot</Button>}
    </Portal>}    
    <SystemOptions config={systemState} socketRef={socketRef} readonly={readonly}/> 
    <HarwareState state={hardwareState}  />
    <Restrictions restrictions={restrictions} socketRef={socketRef} readonly={readonly} />
  </SystemRoot>
}