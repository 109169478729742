import {each} from "lodash"

export default class EventEmitter {
  __listeners: Record<string, Function[]> = {}
  
  addListener(type: string, fn: Function) {
    this.__listeners = this.__listeners || {}
    this.__listeners[type] = this.__listeners[type] || []
    this.__listeners[type].push(fn)
  }
  removeListener(type: string, fn: Function) {
    if (this.__listeners && this.__listeners[type]) {
      let index = this.__listeners[type].indexOf(fn)
      if (index >= 0)
        this.__listeners[type].splice(index, 1)
    }
  }
  emit(type: string, ...args: any[]) {
    if (this.__listeners && this.__listeners[type]) {
      each(this.__listeners[type], fn => fn(...args))
    }
  }
}