import React from 'react';
import {SimpleForm, BooleanInput, SelectInput, TextInput} from "ra-ui-materialui"
import { platforms } from '../../../lib/consts';
import QuickEditButton, { QuickEditButtonProps } from '../../../components/QuickEditButton';
import { useFilteredApplications } from '../../../hooks';

export default function EditControllerButton(props: Omit<QuickEditButtonProps, "children" | "resource">) {
  const applications = useFilteredApplications("admin")
  
  return <QuickEditButton  {...props} resource="controllers" label="Edit">
    <SimpleForm toolbar={undefined} resource="controllers" sx={{
      "& .MuiInputBase-root": {
        minWidth: "250px"
      }
    }}>
      <TextInput disabled source="uuid" />
      <SelectInput source="ApplicationUuid" optionValue="uuid" optionText="description" emptyValue={""}  choices={applications}/>
      <TextInput source="serialNumber" />
      <SelectInput label="Platform" source="platform" choices={platforms} />
      <BooleanInput source="hidden" />
    </SimpleForm>
  </QuickEditButton>
}
