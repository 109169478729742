import {
  List, ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton, ListSubheader, Collapse, Switch, TextField, Checkbox, FormControlLabel, Button, styled
} from "@mui/material";
import { SignalWifi4BarLock, Router as RouterIcon, ExpandLess, Wifi as WifiIcon, ExpandMore, BugReport as  BugReportIcon } from '@mui/icons-material';
import { useState, useCallback, MutableRefObject } from "react";
import { useDeepCompareEffect } from "react-admin";
import { Socket } from "socket.io-client";
import { MonitoringSystemState } from "../../../../../lib/types";


const SystemRoot = styled("div")(({theme}) => ({
  "& .nestedForm": {
    paddingLeft: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    padding: 20,
    "&> button": {
      marginTop: 10,
      width: 100
    }
  },
  "& .itemActive": {
    color: '#fff',
    backgroundColor: "#2b6d5d"
  }, 
  "& .itemWarn": {
    color: '#fff',
    backgroundColor: "#ff6d00"
  }
}))


interface SystemOptionsProps {
  config?: MonitoringSystemState, 
  socketRef: MutableRefObject<Socket | undefined>,
  readonly: boolean
}
export default function SystemOptions({ config, socketRef,  readonly }: SystemOptionsProps) {
  const [dhcpOpen, setDHCPOpen] = useState(false)
  const [rdPinOpen, setRDPinOpen] = useState(false)

  const [debugMode, setDebugMode] = useState(config?.debug)
  const [mbCompat, setMBComat] = useState(config?.mbCompatibiltyMode)
  const [eth0, setEth0] = useState(config?.eth0)
  const [dhcp, setDHCP] = useState(!config?.nodhcp)

  const [rdPin, setRDPin] = useState(config?.rdpin)

  useDeepCompareEffect(() => {
    if (config) {
      setDebugMode(config.debug)
      setMBComat(config.mbCompatibiltyMode)
      setEth0(config.eth0)
      setDHCP(!config.nodhcp)
      setRDPin(config.rdpin)
    }    
  }, [config, setDebugMode, setMBComat, setEth0, setDHCP, setRDPin])

  const debugClass = debugMode === undefined ? "" : (debugMode ? "itemWarn" : "itemActive")
  const mbCompatClass = mbCompat === undefined ? "" : (mbCompat ? "itemWarn" : "itemActive")
  const dhcpClass = eth0 === undefined ? "" : "itemActive"
  const rdPinClass = rdPin === undefined ? "" : "itemActive"
  const networkString = eth0 === undefined ? "--" : (!dhcp ? eth0 : `${eth0} DHCP`)

  const onChangeDebug = useCallback((e: any, val: boolean) => {
    setDebugMode(val)
    socketRef.current?.emit("setDebug", val)
  }, [setDebugMode, socketRef])

  const onChangeMbCompat = useCallback((e: any, val: boolean) => {
    setMBComat(val)
    socketRef.current?.emit("setMBCompat", val)
  }, [setMBComat, socketRef])

  const onSaveNetwork = useCallback(() => {
    socketRef.current?.emit("setNetwork", { nodhcp: !dhcp, eth0 })
  }, [dhcp, eth0, socketRef])

  const onSaveRDPin = useCallback(() => {
    socketRef.current?.emit("setRDPin", { rdpin: rdPin })
  }, [rdPin, socketRef])

  return <SystemRoot>
  <List sx={{
    marginRight: 1,
    minWidth: 300,
    border: "1px solid #CCC"}} subheader={<ListSubheader component="div">Configuraation</ListSubheader>}>
    <ListItem >
      <ListItemAvatar><Avatar className={debugClass}> <BugReportIcon /> </Avatar></ListItemAvatar>
      <ListItemText primary="Debug Mode" secondary={debugMode === undefined ? "--" : (debugMode ? "Enabled" : "Disabled")} />
      {!readonly && <ListItemSecondaryAction>
          <Switch color="primary" checked={debugMode ? true : false} value={debugMode ? true : false} onChange={onChangeDebug} />
      </ListItemSecondaryAction>}
    </ListItem>
    <ListItem>
      <ListItemAvatar><Avatar className={mbCompatClass}> <WifiIcon /></Avatar></ListItemAvatar>
      <ListItemText primary="MB Compatibility" secondary={mbCompat === undefined ? "--" : (mbCompat ? "Enabled" : "Disabled")} />
      {!readonly && <ListItemSecondaryAction>
          <Switch color="primary" checked={mbCompat ? true : false} value={mbCompat ? true : false} onChange={onChangeMbCompat} />
      </ListItemSecondaryAction>}
    </ListItem>
    <ListItem>
      <ListItemAvatar><Avatar className={dhcpClass}> <RouterIcon /> </Avatar></ListItemAvatar>
      <ListItemText primary="DHCP/Static IP" secondary={networkString} />
      {!readonly && 
      <ListItemSecondaryAction onClick={() => setDHCPOpen(!dhcpOpen)}>
        <IconButton edge="end" aria-label="delete">
          {eth0 !== undefined ? (dhcpOpen ? <ExpandLess /> : <ExpandMore />) : ""}
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
    <Collapse in={dhcpOpen} timeout="auto" unmountOnExit>
      <div className={"nestedForm"} >
        <TextField variant="outlined" label="Address" value={eth0} disabled={dhcp} onChange={(e) => setEth0(e.target.value)} />
        <FormControlLabel
          control={<Checkbox checked={dhcp} onChange={() => setDHCP(!dhcp)} />}
          label="DHCP"
        />
        {(eth0 !== config?.eth0 || dhcp === config?.nodhcp) && <Button variant="outlined" color="primary" onClick={onSaveNetwork} >Save</Button>}
      </div>
    </Collapse>
    <ListItem>
      <ListItemAvatar><Avatar className={rdPinClass}> <SignalWifi4BarLock /> </Avatar></ListItemAvatar>
      <ListItemText primary="Remote Disable PIN" secondary={rdPin} />
      {!readonly && <ListItemSecondaryAction onClick={() => setRDPinOpen(!rdPinOpen)}>
        <IconButton edge="end" aria-label="delete" >
          {rdPin !== undefined ? (rdPinOpen ? <ExpandLess /> : <ExpandMore />) : ""}
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
    <Collapse in={rdPinOpen} timeout="auto" unmountOnExit>
      <div className={"nestedForm"} >
        <TextField type="number" variant="outlined" label="Remote Disable PIN" value={rdPin} onChange={(e: any) => setRDPin(e.target.value)} />
        {(rdPin !== config?.rdpin) && <Button variant="outlined" color="primary" onClick={onSaveRDPin} >Save</Button>}
      </div>
    </Collapse>
  </List>
  </SystemRoot>
}

