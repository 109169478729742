import { Paper, styled } from '@mui/material';
import React, { MutableRefObject, RefObject } from 'react';
import { JsonViewer } from '@textea/json-viewer'
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { Button } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { Restore } from '@mui/icons-material';
import { useCallback } from 'react';
import { useNotify } from 'ra-core';
import { Portal } from '@mui/material';
import { Socket } from 'socket.io-client';
import { MonitoringConfig } from '../../../../lib/types';


const ConfigRoot = styled("div")(({theme}) => ({
  "& .paper": {
    padding: 10,    
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row"
  },
  "& .half": {
    width: "49%",
    border: "1px solid #DDD"
  },
  "& .editor": {
    width: "100%",
    maxWidth: 800,
    minHeight: 500,
    "& .CodeMirror": {
      height: "100%",
      minHeight: 500
    }
  },  
}))
interface ConfigViewProps {
  socketRef: MutableRefObject<Socket | undefined>,
  actionsContainer: RefObject<HTMLSpanElement | null>, 
  visible: boolean
  onChange: (config: MonitoringConfig) => void
  config?: MonitoringConfig
}
interface ActionsViewProps {
  onChange: (config: MonitoringConfig) => void
  config?: MonitoringConfig
  visible: boolean
}
interface HardwareViewProps {
  config?: MonitoringConfig  
}
function ActionsView({ config, visible, onChange }: ActionsViewProps) {
  return <CodeMirror value={config?.actions || ""} className={"editor"}
    extensions={[javascript({  })]}
    onChange={(value: string) => onChange({ ...config, actions: value })}    
  />
}
function HardwareView({ config }: HardwareViewProps) {
  return <JsonViewer value={config?.hardware ?? {}} rootName="devices" defaultInspectDepth={2} displayDataTypes={false} />
}

export default function ConfigView({ config, visible, socketRef, actionsContainer, onChange }: ConfigViewProps) {
  const notify = useNotify()
  const onSaveConfig = useCallback(() => {
    if (config?.actions) {
      socketRef.current?.emit("saveConfig", config.actions)
      notify(`Config has been sent!`, { type: "success" })
    }
  }, [socketRef, notify, config?.actions])

  const onResetConfig = useCallback(() => {
    socketRef.current?.emit("saveConfig")
    notify(`Config has been reset!`, { type: "success" })
  }, [socketRef, notify])

  return <ConfigRoot>
    {actionsContainer.current &&  <Portal container={actionsContainer.current}>
      {visible && config?.actions && <Button variant="outlined" color="primary" startIcon={<Restore />} onClick={onResetConfig} >Reset</Button>}
      {visible && config?.actions && <Button variant="outlined" color="primary" startIcon={<SaveOutlined />} onClick={onSaveConfig} >Save</Button>}
    </Portal>}    
    <Paper className={"paper"} >
      <div className={"half"}>
        <ActionsView config={config} onChange={onChange} visible={visible} />
      </div>
      <div className={"half"}>
        <HardwareView config={config} />
      </div>
    </Paper>
  </ConfigRoot>
}