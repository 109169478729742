import React from "react"
import {SaveButton, DeleteButton, Toolbar, ToolbarProps, } from "react-admin"

const DefaultToolbar = (props: ToolbarProps & { allowDelete?: boolean }) => {
  const {allowDelete, ...rest} = props
  return (<Toolbar {...rest}>
        <SaveButton />
        {allowDelete && <DeleteButton redirect="list"/>}
    </Toolbar>)
}

export default DefaultToolbar
