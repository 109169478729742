import React from 'react';
import md5 from "js-md5";
import { CardMedia } from '@mui/material';
import { Profile } from '../../lib/authProvider';
const classes = ({
  profile: {
    margin: "10px 10px",
    width: 64,
    height: 64,
  },
  menu: {
    width: 32,
    height: 32,
    borderRadius: 32,
    backgroundColor: "#FFF"
  },  
});

const variants = {
  profile: {
    size: 64
  },
  menu: {
    size: 32
  },  
}
function profileUrl(profile: Profile, size: number) {
  const hash = profile && profile.email ? md5(profile.email) : "guest"
  return `https://www.gravatar.com/avatar/${hash}?size=${size}&d=mp`
}
export default function ProfileImage({ profile, variant = "profile" }: {profile: Profile, variant: "profile" | "menu"}) {
  return <CardMedia sx={classes[variant]} image={profileUrl(profile, variants[variant].size)} />
}
