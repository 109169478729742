// in src/posts.js
import React from 'react';
import ParamsInput from "./components/ParamsInput"
import { FormTab, TabbedForm, TextInput, NumberInput, } from 'ra-ui-materialui';
import { Create } from '../../components/DefaultDetails';

export default function NamedScriptsCreate() {
  return <Create titleSource="name" >
    <TabbedForm >
      <FormTab label="Summary"  >
        <TextInput source="name" />
        <TextInput source="description" />
        <NumberInput source="priority" />
        <TextInput multiline source="content" rows={20} />
      </FormTab>
      <FormTab label="Params"  >
        <ParamsInput source="params" label="Params" />
      </FormTab>
    </TabbedForm>
  </Create>
}
