
import DeviceIcon from '../gpio/DeviceIcon';
import SyncIcon from '@mui/icons-material/Sync';
import VideocamIcon from '@mui/icons-material/Videocam';
import BugReportIcon from '@mui/icons-material/BugReport';
import MemoryIcon from '@mui/icons-material/Memory';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import WifiIcon from '@mui/icons-material/Wifi';
import { SvgIconProps } from '@mui/material';

interface EventIconProps extends SvgIconProps {
  type: string
}
export default function EventIcon({ type, ...props }: EventIconProps) {
  if (type.match(/activator/)) return <DeviceHubIcon {...props} />
  if (type === "mb") return <WifiIcon {...props} />
  if (type.match(/video/)) return <VideocamIcon {...props} />
  if (type.match(/monitoring/)) return <BugReportIcon {...props} />
  if (type.match(/gpio/)) return <MemoryIcon {...props} />
  if (type.match(/sync/)) return <SyncIcon {...props} />

  return <DeviceIcon name={type} {...props} />
}