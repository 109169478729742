import React from 'react';
import DoorIcon from '@mui/icons-material/MeetingRoom';
import LockIcon from '@mui/icons-material/Lock';
import AlarmIcon from '@mui/icons-material/VpnKey';
import LedIcon from '@mui/icons-material/WbSunny';
import DefaultIcon from '@mui/icons-material/Subject';
import Buzzer from '@mui/icons-material/VolumeUp';
import InputIcon from '@mui/icons-material/SettingsInputComponent';
import ButtonIcon from '@mui/icons-material/TouchApp';
import { SvgIconProps } from '@mui/material';


interface DeviceIconProps extends SvgIconProps {
  name: string
}

export default function DeviceIcon({ name: nameOrig, ...props }: DeviceIconProps) {
  const name = nameOrig.toLowerCase()
  let icon = null
  if (name.match(/door/)) icon = DoorIcon
  if (name.match(/relay/)) icon = LockIcon
  if (name.match(/alarm/)) icon = AlarmIcon
  if (name.match(/lock/)) icon = LockIcon
  if (name.match(/buzzer/)) icon = Buzzer
  if (name.match(/led/)) icon = LedIcon
  if (name.match(/input/)) icon = InputIcon
  if (name.match(/output/)) icon = InputIcon
  if (name.match(/button/)) icon = ButtonIcon
  return React.createElement(icon || DefaultIcon, props, [])
} 