import React from "react"
import {Loading, SimpleForm, TextInput} from "ra-ui-materialui"
import { required} from "ra-core";
import { useAuthProvider } from "ra-core";
import { Edit } from "../../components/DefaultDetails";
import DefaultToolbar from "../../components/DefaultToolbar";
import { passwordConfirmValidate } from "../../lib/validations";

export default function AdminProfile(props: any) {
  const authProvider = useAuthProvider()
  const account = authProvider.getProfile()

  return account.id ? (
      <Edit {...props} basePath="/admins" resource="admins" titleSource="email" id={account.id} redirect="list">
          <SimpleForm toolbar={<DefaultToolbar />} >
            <TextInput source="email" validate={required()} />
            <TextInput source="password" autoComplete="off"  type="password" />
            <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate}  />
          </SimpleForm>
      </Edit>) : <Loading />
}
