import React, { MutableRefObject, ReactNode, useEffect,  useState } from "react"
import { Portal } from '@mui/material'
import {ListButton, EditButton, useResourceContext} from "react-admin"

interface ActionsForTabProps {
  containerRef?: MutableRefObject<HTMLDivElement | undefined>
  children?: ReactNode, 
  hasList?: boolean, 
  hasEdit?: boolean
}
export default function ActionsForTab({ containerRef, children, hasList, hasEdit }: ActionsForTabProps) {
  const resource = useResourceContext()
  const [loaded, setLoaded] = useState(containerRef?.current !== null)
  useEffect(() => setLoaded(!!containerRef?.current), [containerRef, setLoaded])
  return <> {loaded && <Portal container={containerRef?.current}>
    {hasList && <ListButton resource={resource} />}
    {hasEdit && <EditButton resource={resource} />}
    {children}
  </Portal>}
  </>
}