import React from 'react';
import { Datagrid,  Filter,  SelectInput,  TextField} from "ra-ui-materialui";
import GenerateTokenButton from "./components/GenerateTokenButton"
import ApiTokens from "./components/ApiTokens"
import { List } from "../../components/DefaultDetails"

const scopes = [
  { "id": "prod", "label": "Production" },
  { "id": "stg", "label": "Staging" },
  { "id": "dev", "label": "Development" },  
]
function ApplicationFilter() {
  return <Filter >
    <SelectInput label="Environment" optionText="label" source="scope" choices={scopes} alwaysOn />
  </Filter>
  
}

export default function ApplicationList() {
  return <List title="Applications" filters={<ApplicationFilter />} filterDefaultValues={{ scope: "prod" }} >
    <Datagrid expand={ApiTokens}>
      <TextField source="uuid" />
      <TextField source="description" />
      <TextField label="Controllers" source="controllersCount" sortable={false} />

      <GenerateTokenButton />
    </Datagrid>
  </List>
}
