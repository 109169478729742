const PINMAP_40 = [
  -1,
  -1, -1,		/*  P1  P2 */
  2, -1,		/*  P3  P4 */
  3, -1,		/*  P5  P6 */
  4, 14,		/*  P7  P8 */
  -1, 15,		/*  P9  P10 */
  17, 18,		/* P11  P12 */
  27, -1,		/* P13  P14 */
  22, 23,		/* P15  P16 */
  -1, 24,		/* P17  P18 */
  10, -1,		/* P19  P20 */
  9, 25,		/* P21  P22 */
  11, 8,		/* P23  P24 */
  -1, 7,		/* P25  P26 */
  0, 1,		/* P27  P28 */
  5, -1,		/* P29  P30 */
  6, 12,		/* P31  P32 */
  13, -1,		/* P33  P34 */
  19, 16,		/* P35  P36 */
  26, 20,		/* P37  P38 */
  -1, 21		/* P39  P40 */
]

export function gpioBCM(physical: number): number {
  return PINMAP_40[physical]
}
export function gpioPhysical(bcm: number): number {
  return PINMAP_40.indexOf(bcm)
}