// in src/posts.js
import React from 'react';
import { EditProps } from 'ra-ui-materialui';
import { Edit } from '../../components/DefaultDetails';
import AdminForm from "./components/AdminForm"
import { useGetRecordId } from 'react-admin';


export default function AdminEdit(props: EditProps) {
  const id = useGetRecordId()
  return <Edit {...props} titleSource="email" >
    <AdminForm id={id}/>
  </Edit>
}
