import React, { MouseEventHandler, ReactElement } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslate } from 'react-admin';
import { List, ListItemIcon, ListItemText, Divider, Collapse, ListItem } from '@mui/material';

const classes = {
    listItem: {
        paddingLeft: '1rem',
    },
    icon: {
        minWidth: 40
    },
    listItemText: {
        paddingLeft: 0,
        fontSize: '1rem',
        "& p": {
            fontSize: '1rem',
        },
        "& span": {
            fontSize: '1rem',
        }
    },
    sidebarIsOpen: {
        paddingLeft: 4,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
}

type SubMenuProps = {
    handleToggle: MouseEventHandler
    sidebarIsOpen: boolean
    isOpen: boolean,
    name: string
    icon: ReactElement,
    children: ReactElement
}
const SubMenu = ({ handleToggle, sidebarIsOpen, isOpen, name, icon, children }: SubMenuProps) => {
    const translate = useTranslate()
    return (
        <>
            <ListItem
                button
                dense
                onClick={handleToggle}
                sx={classes.listItem}
            >
                <ListItemIcon sx={classes.icon} >{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
                <ListItemText
                    inset
                    primary={translate(name)}
                    sx={classes.listItemText}
                />
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
                <Divider />
            </Collapse>
        </>
    )
}
export default SubMenu
