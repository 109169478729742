import React from 'react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import Terminal from "./components/Terminal"
import './term.css'

export default function TerminalPage() {
  const location = useLocation()
  const token = location.pathname.replace(/^\/ssh\//, "")
  
  useLayoutEffect(() => {
    const title = (new URL(document.location.href)).searchParams.get("title")
    if (title)
      document.title = title
  }, [])
  return <Terminal token={token} />
}



