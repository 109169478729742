import React from 'react';
import { TextField, Datagrid, TextInput, FunctionField,} from "ra-ui-materialui";
import { Filter, NullableBooleanInput, SelectInput, ReferenceInput, DateField } from "ra-ui-materialui"
import EditPackageButton from "./components/EditPackageButton"
import BooleanSwitch from "../../components/BooleanSwitch"
import {RaRecord, useListFilterContext, useRecordContext, useRefresh} from "ra-core"
import { channels, platforms} from "../../lib/consts"
import { uniq, compact, pick } from "lodash"
import CreatePackageButton from './components/CreatePackageButton';
import { List } from '../../components/DefaultDetails';
import DefaultActions from '../../components/DefaultActions';

function PackagesFilter() {
  const { filterValues } = useListFilterContext()
  const platformFilter = pick(filterValues, "platform")
  return (
    <div style={{}}>
      <Filter >
        <TextInput label="Search" source="q" alwaysOn />
        <NullableBooleanInput label="Installable" source="installableOnly" alwaysOn />
        <SelectInput label="Platform" source="platform" choices={platforms} alwaysOn />
        <SelectInput label="Channel" source="channel" choices={channels} alwaysOn />
        <ReferenceInput label="Package Name" source="name" reference="packages/types" filter={platformFilter} alwaysOn style={{ minWidth: 250 }}>
          <SelectInput optionText="name" optionValue="name" />
        </ReferenceInput>
      </Filter>
    </div>
    
  )
}

const PackageExpand = () => {
  const record = useRecordContext()
  return <TextField record={record} label="Notes" source="notes" style={{ whiteSpace: "pre"}} sortable={false} />
}

export default function PackageList() {
  const refresh =  useRefresh()  
  return (
    <List 
      filters={<PackagesFilter />} 
      actions={<DefaultActions ><CreatePackageButton /></DefaultActions>} 
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{ installableOnly: true }}
       >
      <Datagrid expand={PackageExpand} >
        <TextField source="name" />
        <TextField label="Version" source="version" />
        <FunctionField label="Channel" render={(record: RaRecord) => uniq(compact([record.channel, record.sourceBranch])).join("/")} />
        <TextField label="Platform" source="platform" />        
        <BooleanSwitch source="deprecated" onSuccess={refresh}/>
        <DateField source="createdAt" />        
        <EditPackageButton label="Edit" onSubmit={refresh} />
      </Datagrid>
    </List>
  )
}
