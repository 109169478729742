import React, { useEffect } from 'react';
import ioClient, {Socket} from "socket.io-client"
const server = "https://webtty.icacs.io"
declare global {
  interface Window {
    lib: any
    hterm: any
  }
}

interface TerminalProps {
  token: string
  className?: string
}
class SocketExt extends Socket {
  term: any
  buf: any
}
export default function Terminal({ token, className }: TerminalProps) {

  useEffect(() => {
    const { lib, hterm } = window
    const socket: SocketExt = ioClient(server, { path: `/mb/consumers/webtty/${token}` }) as SocketExt
    class Wetty {
      argv_: any
      io: any
      constructor(argv: any) {
        this.argv_ = argv;
        this.io = null;
      }
      run() {
        this.io = this.argv_.io.push()
        this.io.onVTKeystroke = (str: string) => socket.emit('input', str)
        this.io.sendString = (str: string) => socket.emit('input', str)
        this.io.onTerminalResize = (col: number, row: number) => socket.emit('resize', { col: col, row: row });
      }
    }
    socket.on('connect',  () => {
      lib.init(() => {
        hterm.defaultStorage = new lib.Storage.Local()        
        const term = socket.term = new hterm.Terminal()
        
        term.decorate(document.getElementById("terminal"))
        term.setCursorPosition(0, 0);
        term.setCursorVisible(true);
        term.prefs_.set('ctrl-c-copy', true);
        term.prefs_.set('ctrl-v-paste', true);
        term.prefs_.set('use-default-window-copy', true)
        term.runCommandClass(Wetty, "");
        socket.emit("resize", {
          col: term.screenSize.width,
          row: term.screenSize.height
        })
        if (socket.buf && socket.buf !== '') {
          term.io.writeUTF16(socket.buf);
          socket.buf = '';
        } else {
          socket.emit("input", "\n")
        }
        setInterval(() => {
          socket.emit("resize", {
            col: term.screenSize.width,
            row: term.screenSize.height
          })
        }, 300000)
        
      })
    })

    socket.on("output", data => {
      if (!socket.term) {
        socket.buf += data;
        return
      }    
      socket.term.io.writeUTF16(data)
    })
    return () => {
      socket.disconnect()
    }
  }, [token])
  return <div id="terminal" className={className} style={{
    display: "block",
    position: "relative",
    width: "100%",
    height: "100vh",
    minHeight: 500
  }}></div>
}



