import React from 'react';
import Drawer from '@mui/material/Drawer'
import List  from '@mui/material/List';
import ListItem  from '@mui/material/ListItem';
import ListItemIcon  from '@mui/material/ListItemIcon';
import ListItemText  from '@mui/material/ListItemText';
import IconInbox from '@mui/icons-material/Inbox';
import { ListSubheader } from '@mui/material';

type Release = { version: string, name: string, channel: string}
interface PackagesProps {
  label: string, 
  releases: Release[], 
  onInstall: (release: Release) => void
}
interface InstallSelectionProps {
  open: boolean,
  releases: Release[],
  onClose: () => void, 
  onInstall: (release: Release) => void
}
function Packages({ label, releases, onInstall }: PackagesProps) {
  return releases.length > 0 ? <List component="nav" style={{ width: 250 }} subheader={<ListSubheader component="div" >{label}</ListSubheader>}>
    {releases.map(release => (<ListItem key={`release_${release.version}`} button onClick={() => onInstall(release)}>
      <ListItemIcon>
        <IconInbox />
      </ListItemIcon>
      <ListItemText primary={release.name} secondary={<span>{release.version}</span>} />
    </ListItem>))}
  </List> : <span />
}

export default function InstallSelection({ open, releases, onClose, onInstall }: InstallSelectionProps) {
  const stable = open ? releases.filter(release => release.channel === "stable") : []
  const beta = open ? releases.filter(release => release.channel === "beta") : []
  const other = open ? releases.filter(release => release.channel !== "beta" && release.channel !== "stable") : []

  return <Drawer anchor="right" open={open} onClose={onClose}>
    <div tabIndex={0} role="button" onClick={onClose} onKeyDown={onClose} >
      <Packages label="Stable" releases={stable} onInstall={onInstall} />
      <Packages label="Beta" releases={beta} onInstall={onInstall} />
      <Packages label="Other" releases={other} onInstall={onInstall} />     
    </div>
  </Drawer>
}
 