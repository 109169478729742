import { Icon, } from "@mui/material";
import { Button, Datagrid, DateField, FunctionField, ListView, TextField } from "ra-ui-materialui";
import {map} from "lodash"
import { usePageTitle } from "../../hooks";
import { makeStyles } from "../../lib/makeStyles";
import { ListContextProvider, RaRecord, useList } from "react-admin";

const useStyles = makeStyles(theme => ({ 
  paper: {
    padding: 10,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },  
  main: {
    display: 'flex',
    justifyContent: "space-between"
  },
  actions: {
    display: "flex"
  }
}))

const urls = [
  "https://rpi.icacs.io/images/2023-12-28-mdcCore-14.0.1-43-stable.img.gz",
  "https://rpi.icacs.io/images/2023-12-28-iTitan-14.0.1-43-stable.img.gz",
  "https://rpi.icacs.io/images/2023-02-02-mdcCore-9.1.0-39-stable.img.gz",
  "https://rpi.icacs.io/images/2023-02-02-iTitan-9.1.0-39-stable.img.gz",
];
const firmwareData = (url: string): RaRecord => {
  const [ , date, name, version] = url.match(/https:\/\/[^/]+\/images\/(\d{4}-\d{2}-\d{2})-(\w+)-(.+?).img.gz/) ?? []
  return ({
    id: url,
    platform: "tinycore" + version.split(".")[0],
    name,
    date: new Date(date).toLocaleDateString("en-US"),
    version,
    url
  })  
}
export default function Firmware() { 
  const classes = useStyles();
  usePageTitle("Firmware")
  const firmwareImages = map(urls, firmwareData)
  const imagesListData = useList({ data: firmwareImages, page: 1, perPage: firmwareImages.length, resource: "firmwmare"})  
  return (
    <ListContextProvider value={imagesListData} >
      <ListView >
        <Datagrid  bulkActionButtons={false}>
          <TextField source="platform" />
          <TextField source="name" />
          <DateField source="date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
          <TextField source="version" />
          <FunctionField sx={classes.actions} render={(record: RaRecord) => <>
            <a href={record.url} target="_blank" rel="noreferrer">
              <Button title="Download"><Icon>download</Icon></Button>
            </a>
          </>} />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  )
}
