import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import List  from '@mui/material/List';
import ListItem  from '@mui/material/ListItem';
import ListItemIcon  from '@mui/material/ListItemIcon';
import ListItemText  from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import { Button, DateField } from 'ra-ui-materialui'
import CommandButton, { CommandButtonProps } from '../../../components/CommandButton';
import { RaRecord } from 'react-admin';

interface InstallButtonProps {
  uuid: string
  packageReleaseUuid: string
}
interface InstallDialogProps {
  record: RaRecord | null
  ControllerUuid: string, 
  onClose: () => void
}
const InstallButton = ({ uuid, packageReleaseUuid, ...props }: InstallButtonProps & Omit<CommandButtonProps, "path">) => <CommandButton
  path={`controllers/${uuid}/install/${packageReleaseUuid}`}
  {...props} />


export default function InstallDialog({ record, ControllerUuid, onClose, ...props }: InstallDialogProps & DialogProps) {
  if (!record) {
    return <div />
  }
  const message = `Package ${record.name} scheduled successfully`
  return (
    <Dialog onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" {...props}>
      <DialogTitle id="alert-dialog-title">Install Package "{record.name}"?</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemIcon><Icon>grade</Icon></ListItemIcon>
            <ListItemText primary="Version" secondary={<span>{record.version}</span>} />
          </ListItem>
          <ListItem>
            <ListItemIcon><Icon>description</Icon></ListItemIcon>
            <ListItemText primary="Notes" secondary={<span>{record.notes}</span>} secondaryTypographyProps={{ style: { whiteSpace: "pre"} }} />
          </ListItem>
          <ListItem>
            <ListItemIcon><Icon>access_time</Icon></ListItemIcon>
            <ListItemText primary="Published" secondary={<DateField record={record} source="createdAt" />} />
          </ListItem>
          <ListItem button component="a" href={record.url} target="_blank">
            <ListItemIcon><Icon>get_app</Icon></ListItemIcon>
            <ListItemText primary="Download" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} label="Cancel">
          <Icon>cancel</Icon>
        </Button>
        <InstallButton message={message} uuid={ControllerUuid} packageReleaseUuid={record.uuid} onSend={onClose} >
          <Button label="Install" ><Icon>get_app</Icon></Button>
        </InstallButton>
      </DialogActions>
    </Dialog>)
}
