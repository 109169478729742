import {memoize, get} from "lodash"

const json = (value: any) => ((typeof value === "object") ? null : "invalid json")
const passwordConfirmValidate = (value: string, allValues: any) => {
    if(allValues.password !== undefined && value !== allValues.password) {
        return "validation.passwordConfirm"
    }
    return undefined
}
const moreThan = (source: string, sourceName: string | null = null) =>
  (value: number, allValues: any) => {
    if(allValues[source] && value && value <= allValues[source]) {
      return ({
        message: "validation.moreThan",
        args: {source: sourceName || source}
      })
    }
    return undefined
  }
const lessThan = (source: string, sourceName: string | null = null) =>
    (value: number, allValues: any) => {
      if(allValues[source] && value && value >= allValues[source]) {
        return ({
          message: "validation.lessThan",
          args: {source: sourceName || source}
        })
      }
      return undefined
    }
const phone = () => (value: string, allValues: any) => {
  if(value && !value.match(/^\+?[\d]+$/)) {
    return "validation.phoneNumber"
  }
  return undefined
}

const requiredIf = memoize(function(source: string){
  return function(value: string, allValues: any) {
    if(get(allValues, source) && !value) {
      return "ra.validation.required"
    }
    return undefined
  }
})
export {phone, json, passwordConfirmValidate, moreThan, lessThan, requiredIf}
