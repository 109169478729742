import React from "react"
import { Datagrid, FunctionField, DateField, TextField, Filter, TextInput, SelectInput, NullableBooleanInput} from "ra-ui-materialui"
import { RaRecord, usePermissions, useRefresh} from "ra-core"
import { platforms} from "../../lib/consts"
import EditControllerButton from "./components/EditControllerButton"
import ConnectivityIcon from "./components/ConnectivityIcon"
import { get, mapKeys } from "lodash"
import { makeStyles } from "../../lib/makeStyles"
import { List } from "../../components/DefaultDetails"
import ShowLink from "../../components/ShowLink"
import ApplicationReferenceField from "./components/ApplicationReferenceField"
import QuickDeleteButton from "../../components/QuickDeleteButton"
import { Box } from "@mui/material"
import { useFilteredApplications } from "../../hooks"
import { isApplicationAdmin } from "../../lib/helper"


const useStyles = makeStyles(() => ({
  pre: {
    whiteSpace: "pre"
  },
  icacs: {
    fontStyle: "italic",
    fontWeight: "bold"
  },
  core: {
    fontStyle: "italic"
  },
  platform: {
    textDecoration: "underline"
  },
  actions: {
    display: "flex"
  },
  filter: {
    "& .RaFilter-form": {
      alignItems: "baseline"
    }
  }
}))
function ControllersFilter() {
  const filteredApps = useFilteredApplications("viewer")

  const classes = useStyles()
  return <Box sx={classes.filter}>
    <Filter >
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput source="ApplicationUuid" choices={filteredApps} optionText="description" optionValue="uuid" alwaysOn style={{ minWidth: 250 }} />
      <SelectInput source="platform" label="Platform" choices={platforms} alwaysOn />
      <NullableBooleanInput label="Unassigned" source="unassigned" alwaysOn />
      <NullableBooleanInput label="Outdated" source="hidden" alwaysOn />
    </Filter>
  </Box>
}
function getPackages(controller: RaRecord) {
  const status = controller.status
  return get(status, "packages") ||
    get(status, "system.packages") ||
    mapKeys(get(status, "system.packageinfo", {}), (v, k) => k.replace(/-version$/, ""))
}

export default function ControllerList() {
  const refresh = useRefresh()
  const classes = useStyles()
  const { permissions } = usePermissions()
  return <List filters={<ControllersFilter />} perPage={50}  filterDefaultValues={{ hidden: false }}>
    <Datagrid sx={{
      "& .RaDatagrid-rowCell.pre": classes.pre,
      "& .RaDatagrid-rowCell .platform": classes.platform,
      "& .RaDatagrid-rowCell .core": classes.core,
      "& .RaDatagrid-rowCell .icacs": classes.icacs,
    }}>
      <ConnectivityIcon label="Status" source="uuid" />                             
      
      <FunctionField label="Serial Number" render={(record: RaRecord) => <>
        {record.serialNumber && <ShowLink  source="serialNumber" /> }
        {!record.serialNumber && <ShowLink source="uuid" />}         
      </>} />
      <FunctionField cellClassName={"pre"} label="Platform" render={(record: RaRecord) => <>
        <TextField className={"platform"} source="platform" /><br />
        <TextField className={"core"} source="core" record={getPackages(record)} emptyText="-" /><br />
        <TextField className={"icacs"} source="icacs2" record={getPackages(record)} />
      </>} />
      <FunctionField label="Device" render={(record: RaRecord) => <>
        <TextField source="meta.serialNumber" record={record}/><br/>
        <TextField source="meta.macAddr" record={record} /><br />
        <TextField source="publicIp" record={record} />  <br />
        <TextField color="textSecondary" source="uuid" />
      </>}/>
      
      <ApplicationReferenceField source="ApplicationUuid" />
      <DateField  label="Connected At" source="lastAccessedAt" />
      <FunctionField sx={classes.actions} render={(record: RaRecord) => isApplicationAdmin(permissions, record.ApplicationUuid) && <>    
        <EditControllerButton onSubmit={refresh} label="" />
        <QuickDeleteButton onSuccess={refresh} label="" resource="controllers" />
      </>} />
    </Datagrid>
  </List>  
}
