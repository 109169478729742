import React from 'react';
import QuickEditButton, { QuickEditButtonProps } from "../../../components/QuickEditButton"
import { SimpleForm, TextField, TextInput, BooleanInput, SelectInput} from "ra-ui-materialui"
import { platforms } from '../../../lib/consts';

export default function EditPackageButton(props: Partial<QuickEditButtonProps>) { 
  return <QuickEditButton {...props} resource="packages">
    <SimpleForm toolbar={undefined} >
      <TextField source="name"  />
      <TextField label="Version" source="version" />
      <SelectInput label="Platform" source="platform" choices={platforms} />
      <TextInput multiline label="Release notes" source="notes" />
      <BooleanInput label="Deprecated" source="deprecated"   />
    </SimpleForm>
  </QuickEditButton>
}