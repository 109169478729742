const i18n = ({
  resources: {
    "applications": {
      name: "Application |||| Applications",
    },
    "packages": {
      name: "Package |||| Packages",
    },
    "named-scripts": {
      name: "Script |||| Scripts",
    },
    admins: {
      name: "Admin |||| Admins",
    },
    controllers: {
      name: "Controller |||| Controllers",
    }
  },
  titles: {
    logout: "Logout",
    create: "Create",
    login: "Login",
    profile: "Profile",
    config: "Config"
  },
})
export default i18n