import { useState } from "react";
import {
  List, ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton, ListItemIcon, ListSubheader, Collapse, ListItemButton, styled
} from "@mui/material";
import { Warning, ExpandMore, FlashOn as FlashOnIcon, FlashOff as FlashOffIcon, Memory as MemoryIcon, SignalCellular3Bar } from "@mui/icons-material";
import { isEmpty, map } from "lodash"
import { MonitoringHardwareState } from "../../../../../lib/types";

const HarwareStateRoot = styled("div")({
  "& .itemActive": {
    color: '#fff',
    backgroundColor: "#2b6d5d"
  },
  "& .itemError": {
    color: '#fff',
    backgroundColor: "#cd0e0e"
  },
  "& .itemWarn": {
    color: '#fff',
    backgroundColor: "#ff6d00"
  },
  "& .iconWarn": {
    color: "#cd0e0e",
    minWidth: "30px"
  }
})

interface HarwareStateProps {
  state?: MonitoringHardwareState
}

export default function HarwareState({ state }: HarwareStateProps) {
  const temClass = parseFloat(state?.temp ?? "0") > 60 ? "itemError" : "itemActive"
  const memClass = parseInt(state?.mem ?? "0") / parseInt(state?.memTotal ?? "1") > 0.8 ? "itemError" : "itemActive"
  const cpuClass = parseInt(state?.cpu ?? "0" ) < 600 ? "itemError" : "itemActive"
  const voltageClass = !isEmpty(state?.underVoltage ?? []) ? "itemError" : "itemActive"

  const underVoltageHasIssues = !isEmpty(state?.underVoltage)
  const [uvOpen, setUvOpen] = useState(false)

  return <HarwareStateRoot>
   <List sx={{ marginRight: 1, minWidth: 300, border: "1px solid #CCC" }} subheader={<ListSubheader component="div">Hardware state</ListSubheader>}>
    <ListItem>
      <ListItemAvatar><Avatar className={state?.temp ? temClass : ""} >℃</Avatar></ListItemAvatar>
      <ListItemText primary="Temperature" secondary={state?.temp || "--"} />
    </ListItem>
    <ListItem>
      <ListItemAvatar><Avatar className={state?.mem ? memClass : ""}> <MemoryIcon /></Avatar></ListItemAvatar>
      <ListItemText primary="Memory Usage" secondary={state?.mem ? `${state.mem}/${state.memTotal}` : '--'} />
      {false && <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <ExpandMore />
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
    <ListItem>
      <ListItemAvatar><Avatar className={state?.cpu !== undefined ? cpuClass : ""}> <SignalCellular3Bar /> </Avatar></ListItemAvatar>
      <ListItemText primary="CPU" secondary={state?.cpu || "--"} />
      {false && <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <ExpandMore />
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
    <ListItem>
      <ListItemAvatar><Avatar className={state?.underVoltage !== undefined ? voltageClass : ""}>
        {underVoltageHasIssues ? <FlashOnIcon /> : <FlashOffIcon />}
      </Avatar></ListItemAvatar>
      <ListItemText primary="Voltage" secondary={state?.underVoltage === undefined ? "--" : (underVoltageHasIssues ? "Has Issues" : "No Issues")} />
      {underVoltageHasIssues && <ListItemSecondaryAction onClick={() => setUvOpen(!uvOpen)}>
        <IconButton edge="end" aria-label="delete">
          <ExpandMore />
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
    <Collapse in={uvOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
          {map(state?.underVoltage, issue => <ListItemButton key={issue} sx={{ paddingLeft: (theme) => theme.spacing(4) }}>
          <ListItemIcon className={"iconWarn"}><Warning /></ListItemIcon>
          <ListItemText primary={issue} />
        </ListItemButton>)}
      </List>
    </Collapse>
  </List>  
  </HarwareStateRoot>
}
