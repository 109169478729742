import React from 'react'
import { Button } from 'ra-ui-materialui'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
interface ScriptDialogProps {
  open: boolean, 
  text: string, 
  onClose: () => void
}
export default function ScriptDialog({ open, text, onClose }: ScriptDialogProps) {
  return <Dialog open={open} onClose={onClose} fullScreen aria-labelledby="info-dialog-title" aria-describedby="info-dialog-description">
    <DialogContent>
      <AppBar >
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close"> <CloseIcon /> </IconButton>
          <Typography variant="h6" color="inherit" style={{ flex: 1 }}>Script Source</Typography>
          <Button color="inherit" onClick={onClose} label="Close" />
        </Toolbar>
      </AppBar>
      <pre style={{ position: "relative", top: 50 }}>{text.replace(/^data:/, "")} </pre>
    </DialogContent>
  </Dialog>
} 
 