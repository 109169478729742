import React, {   useEffect } from "react"
import { LayoutProps, GetListParams, Layout } from "react-admin";
import Menu from "./Menu"
import AppBar from "./AppBar"
import { useDataProvider } from "react-admin";
import storage from "../lib/storage";
import { defaults, get, map } from "lodash"
import authProvider from "../lib/authProvider";
import { useNavigate } from "react-router";

function setStorageHash(name: string, data: any[]) {
  storage.setObject(name, data.reduce((acc, obj) =>
    Object.assign(acc, { [obj.id]: obj })
    , {}))
}
const params: GetListParams = {
  pagination: {page: 0, perPage: 100},
  sort: {field: "id", order: "asc"},
  filter: {}  
}
const defaultMeta = {
  "update-package-status": { icon: "sync" },
  "logs": { icon: "sd_storage" },
  "monitoring": { icon: "developer_mode" },
  "ssh": { icon: "share" }
}

export default function MyLayout(props: LayoutProps) {
  const dataProvider = useDataProvider()
  const token = authProvider.getToken()
  const navigate = useNavigate()

  useEffect(() => {
    dataProvider
      .getList("applications", params)
      .then(({ data }: { data: any }) => setStorageHash("applications", data))
    dataProvider
      .getList("named-scripts", params)
      .then(({ data }: { data: any }) => setStorageHash("namedScripts", map(data, script => ({ ...script, meta: defaults(script.meta ?? {}, get(defaultMeta, script.name, {})),}))))
  }, [dataProvider])

  useEffect(() => {
    if (token) {
      const nextPathName = storage.getString("nextPathName")
      if (nextPathName) {
        storage.unset("nextPathName")
        navigate(nextPathName)
      }
    }
  }, [navigate, token])

  return <Layout {...props} appBar={AppBar} menu={Menu} />
}


