import { find, includes, intersection, isNil } from "lodash"

export function redirectPath(path: string | null = null) {
  let [, , redirect,] = document.location.href.match(/(\?|&)redirect=(.+)(&|$)/) || []
  return redirect || path || ""
}
export function checkPermissions(permissions: string[], ...required: string[]) {
  return intersection(permissions, required).length > 0
}

type Role = { role: string, ApplicationUuid: string}

export function getApplicationUuids(permissions: Role[], role: string): string[] | undefined {
  if (isSuperAdmin(permissions)) return undefined
  if (role === "viewer") return permissions.map((role) => role.ApplicationUuid)
  return permissions.filter((r) => r.role === role).map((role) => role.ApplicationUuid)
}

export function isSuperAdmin(permissions: Role[]): boolean {
  return find(permissions, (role) => role.role === "root") != null  
}

export function isApplicationAdmin(permissions: Role[], ApplicationUuid: string): boolean {
  return isSuperAdmin(permissions) || isNil(ApplicationUuid) || includes(getApplicationUuids(permissions, "admin"), ApplicationUuid)
}