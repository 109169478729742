import React, { useState, useCallback, useRef } from 'react';
import { Datagrid, TabbedShowLayout, ReferenceManyField, Pagination, DateField, Tab, TextField} from 'ra-ui-materialui';
import {FunctionField, Button} from 'ra-ui-materialui';
import {RaRecord, useGetRecordId, usePermissions, useRefresh, useShowController} from "ra-core"
import InstallDialog from "./components/InstallDialog"
import { get, mapKeys } from "lodash"
import InstallSelection from "./components/InstallSelection"
import DebugScriptDialog from "./components/DebugScriptDialog"
import ScriptDialog from "./components/ScriptDialog"
import EditControllerButton from "./components/EditControllerButton"
import ConnectivityIcon from "./components/ConnectivityIcon"
import {v4 as makeUuid} from 'uuid';
import { makeStyles } from '../../lib/makeStyles';
import { Box, Container, Icon } from '@mui/material';
import storage, { useScopedStorageState } from '../../lib/storage';
import CommandButton, { CommandButtonProps } from '../../components/CommandButton';
import { useNavigate } from 'react-router';
import { Show } from '../../components/DefaultDetails';
import DefaultActions from '../../components/DefaultActions';
import QuickDeleteButton from '../../components/QuickDeleteButton';
import ActionsForTab from '../../components/ActionsForTab';
import ApplicationReferenceField from './components/ApplicationReferenceField';
import { timeOptions } from '../../lib/consts';
import Monitoring from '../Debug/components/Monitoring';
import { isApplicationAdmin } from '../../lib/helper';

const useStyles = makeStyles(() => ({
  terminal: {
    minHeight: 500
  },
  pre: {
    whiteSpace: "pre"
  },
  preList: {
    display: "block",
    fontFamily: "monospace",
    whiteSpace: "pre-line",
    margin: "2em 0px"
  } ,
  deleteController: {
    display: "flex",
    flexFlow: "column",
    alignItems: "end"
  }
}))
interface ScriptRunButtonParams {
  name: string, 
  uuid: string, 
  params: any, 
  onSuccess: () => void
}
interface ClearButtonParams extends Omit<CommandButtonProps, "path" | "children"> {
  uuid: string
  label: string
  params: any
}
const ScriptRunButton = ({ name, uuid, params, onSuccess }: ScriptRunButtonParams) => {
  const namedScripts = storage.getObject("namedScripts")
  const script = namedScripts[name]
  return script ? <CommandButton 
      path={`controllers/${uuid}/execute/${name}`} 
      onSuccess={onSuccess} 
      params={params}
      message={`Script ${name} scheduled successfully`}> 
    <Button label={script.description} >
      <Icon>{script.meta.icon || "send"}</Icon>
    </Button>    
  </CommandButton> : <span/>
}
const ClearButton = ({ uuid, label, params, ...props }: ClearButtonParams) => {
  const path = `controllers/${uuid}/clear`
  return <CommandButton path={path} params={params} message="Clear success" {...props}>
    <Button label={label}><Icon>clear</Icon></Button>
  </CommandButton>
}

function getPackages(controller: RaRecord) {
  const status = controller.status
  return get(status, "packages") ||
    get(status, "system.packages") ||
    mapKeys(get(status, "system.packageinfo", {}), (v, k)=> k.replace(/-version$/, ""))
}


export default function ControllerShow() {
  const classes = useStyles()
  const { permissions } = usePermissions()
  const refresh  = useRefresh()
  const navigate = useNavigate()
  const redirectList = useCallback(() => navigate("/controllers"), [navigate])
  const { record: controller  }  = useShowController({})
  const isAdmin = isApplicationAdmin(permissions, controller?.ApplicationUuid)

  const uuid: string  = useGetRecordId() as string

  const [releases, setReleases] = useState([])
  const [installRecord, setInstallRecord] = useState(null)
  const [scriptText, setScriptText] = useState(null)
  const [timeZone, setTimezone] = useState<string | undefined>(undefined)
  const switchTimezone = useCallback(() => setTimezone(timeZone === "UTC" ? undefined : "UTC"), [timeZone, setTimezone])
  const containerRef = useRef()
  const [sshParams, ] = useState({ sessionToken: makeUuid() })
  const [token ] = useScopedStorageState("monitoring.token", uuid, () => [makeUuid()])

  const sshRunSuccess = useCallback(() => {
    window.open(`${document.location.origin}/ssh/${sshParams.sessionToken}?title=${controller.serialNumber}`)
  }, [sshParams.sessionToken, controller?.serialNumber])
  
  
  const packages = controller ? getPackages(controller) : []
  const onExpand = useCallback(() => {
    window.open(`${document.location.origin}/monitoring/${controller.uuid}/${token}?title=${controller.serialNumber}`)
  }, [token, controller?.uuid, controller?.serialNumber]) 

  return <>
    <InstallSelection open={releases.length > 0} releases={releases} onClose={() => setReleases([])} onInstall={(release: any) => setInstallRecord(release)} />
    <ScriptDialog text={scriptText || ""} open={Boolean(scriptText)} onClose={() => setScriptText(null)} />
    <InstallDialog ControllerUuid={uuid} record={installRecord} open={Boolean(installRecord)} onClose={() => setInstallRecord(null)} />

    <Show titleSource="serialNumber" actions={<DefaultActions hasList={false} hasEdit={false} portalRef={containerRef} />}  >
      <TabbedShowLayout >
        <Tab label="Info"  >
          <ActionsForTab containerRef={containerRef} hasList={true} hasEdit={false} >
            <EditControllerButton label="Edit" onSubmit={refresh} />
          </ActionsForTab>
          <TextField label="Serial Number" source="serialNumber" />
          <ApplicationReferenceField label="Application" source="ApplicationUuid"  />         
          <TextField label="Platform" source="platform" />
          <FunctionField label="RPI Info (Serial/MAC)" render={(record: RaRecord) =>
            <>
              <TextField record={record} label="RPI Serial" source="meta.serialNumber" />
              &nbsp;
              (<TextField record={record} label="MAC Address" source="meta.macAddr" />  )              
            </>
          } />          
          <TextField label="Public IP" source="publicIp" />
          <TextField label="Remote State" source="status.system.state.remote" />
          <DateField label={`Connected At (${timeZone || "Local"})`} source="lastAccessedAt" onDoubleClick={switchTimezone} options={{ timeZone, ...timeOptions }} />
          <ConnectivityIcon label="Connectivity Status" source="uuid" /> 
          <Box sx={classes.deleteController}>
            {isAdmin && <QuickDeleteButton onDelete={redirectList} />}            
          </Box>          
        </Tab>
        {isAdmin && <Tab label="Scripts" >
          <ActionsForTab containerRef={containerRef} hasList={false} hasEdit={false} >
            <DebugScriptDialog uuid={uuid} onSuccess={refresh} />
            <ClearButton label="Clear" uuid={uuid} params={{ scripts: true }} onSuccess={refresh} />
          </ActionsForTab>
          <ReferenceManyField key="scripts" label={false} reference="scripts" target="ControllerUuid" sort={{ field: 'createdAt', order: 'ASC' }} pagination={<Pagination />}>
            <Datagrid >
              <TextField label="namespace" source="namespace" sortable={false} />
              <TextField label="source" source="source" sortable={false} />
              <TextField label="priority" source="priority" sortable={false}/>              
              <DateField label="createdAt" source="createdAt" sortable={false} />
              <FunctionField render={(record: RaRecord) => 
                <Button label="View" onClick={() => setScriptText(record.url)}></Button>
                } />
              <QuickDeleteButton onSuccess={refresh} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>}
        {isAdmin &&<Tab label="Logs" >
          <ActionsForTab containerRef={containerRef} hasList={false} hasEdit={false} >
            <DebugScriptDialog  uuid={uuid} onSuccess={refresh} />
            <ClearButton label="Clear" uuid={uuid} params={{ logs: true }} onSuccess={refresh} />
          </ActionsForTab>
          <ReferenceManyField key="logs" label={false} reference="logs" target="ControllerUuid" sort={{ field: 'createdAt', order: 'DESC' }} pagination={<Pagination />}>
            <Datagrid sx={{
              "& .time": {
                minWidth: 200,
                verticalAlign: "baseline",
              },
              "& .logContent": {
                width: 'calc(100% - 200px)',
                wordBreak: "break-word"
              }
            }}>
              <FunctionField cellClassName="logContent" source="content" render={(record: RaRecord) => <span style={classes.preList}>{record.content}</span>}></FunctionField>
              <TextField label="createdAt" source="createdAt" cellClassName='time' fontSize="0.8rem" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>}
        <Tab label="Packages">
          <ReferenceManyField label={false} reference="packages/types" target="ControllerUuid" perPage={50}>
            <Datagrid >
              <TextField label="Name" source="name" />
              <FunctionField label="Current Version" render={(record: RaRecord) => packages[record.name] } />
              <TextField label="Latest Version" source="versions.0.version" />
              <TextField label="Release Notes" source="versions.0.notes" sx={classes.pre} />
              <DateField label="Published" source="versions.0.createdAt" />
              {isAdmin && <FunctionField label="Install" render={(record: RaRecord) => <Button onClick={() => setReleases(record.versions)} >
                <><Icon>get_app</Icon>Install</>
              </Button>} />}              
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Monitoring">
          <ActionsForTab containerRef={containerRef} hasList={false} hasEdit={false} >
            {isAdmin && <ScriptRunButton name="ssh" uuid={uuid} params={sshParams} onSuccess={sshRunSuccess} />}            
          </ActionsForTab>
          <>
            <Container maxWidth="xl" >
              <Monitoring token={token} uuid={uuid} ApplicationUuid={controller?.ApplicationUuid} onExpand={onExpand} /> 
            </Container>          
          </>          
        </Tab>  
      </TabbedShowLayout>
    </Show>
  </>
}
