/* eslint-disable react/no-multi-comp */
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, List, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { fromPairs, map, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { Button, RaRecord } from 'react-admin';
import { makeStyles } from '../../../lib/makeStyles';
import CommandButton, { CommandButtonProps } from "../../../components/CommandButton"

interface ScriptButtonProps extends Omit<CommandButtonProps, "path" | "message">{
  scriptName: string,
  uuid: string  
}

const ScriptButton = ({ uuid, scriptName, ...props }: ScriptButtonProps) => <CommandButton
  path={`controllers/${uuid}/execute/${scriptName}`}
  message={`Script ${scriptName} scheduled successfully`}
  {...props} />

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: 500
  },
  listItem: {
    alignItems: "flex-end" 
  }
}))
interface RunDialogProps {
  open: boolean,
  record: RaRecord | null,
  onClose: () => void
}
export default function RunDialog({ onClose, record, open }: RunDialogProps) {  
  const classes = useStyles()
  const recordParams = record ? record.params : []
  const params = uniqBy([{ name: "uuid", description: "Controller Uuid" }, ...recordParams], "name")
  const [args, setArgs] = useState(fromPairs(map(params, param => [param.name, param.default])))
  
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setArgs({
      ...args,
      [event.target.name]: event.target.value
    })    
  }
  if (!record) return <span />
  return (
    <Dialog onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open >
      <DialogTitle id="alert-dialog-title">Run {record.name}?</DialogTitle>
      <DialogContent sx={classes.dialog}  >
        <List >
          <ListItem >
            <ListItemIcon><Icon>grade</Icon></ListItemIcon>
            <ListItemText primary={record.description || record.name} />
          </ListItem>          
          {map(params, (param: any) =>
            <ListItem key={param.name} sx={classes.listItem}>
              <ListItemIcon><Icon>list</Icon></ListItemIcon>
              <ListItemText>
                <TextField
                  label={param.description}
                  name={param.name}
                  defaultValue={param.default}
                  onChange={onChange}
                />
              </ListItemText>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>      
        <Button onClick={onClose} color="primary" label="Cancel" >
          <Icon>cancel</Icon>
        </Button>
        <ScriptButton uuid={args.uuid} scriptName={record.name} params={args} onSend={onClose} >
          <Button label="Run">
            <Icon>send</Icon>
          </Button>
        </ScriptButton>
      </DialogActions>
    </Dialog>)
}
