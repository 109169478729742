import React, { useCallback, useState } from 'react'
import { Button } from 'ra-ui-materialui'
import CloseIcon from '@mui/icons-material/Close'
import storage from "../../../lib/storage"
import { makeStyles } from '../../../lib/makeStyles'
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, TextField, Typography } from '@mui/material'
import CommandButton from '../../../components/CommandButton'

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }})
)

function extractDefaultScript(content: string) {
  return (content || "").replace(/{{([\s\S]+?)}}/g, "").replace(/^#!(.+[\n])/g, '')
}
interface DebugScriptDialogProps {
  uuid: string
  onSuccess: () => void
}
export default function DebugScriptDialog({ uuid, onSuccess }: DebugScriptDialogProps) {
  const classes = useStyles()
  const namedScripts = storage.getObject("namedScripts")
  const script = namedScripts.debug
  
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(extractDefaultScript(script.content))
  const onClose = useCallback(() => setOpen(false), [setOpen])
  const onOpen = useCallback(() => setOpen(true), [setOpen])
  const onChange = useCallback((e: any) => setValue(e.target.value), [setValue])
  
  return <>
    <Button label={script.description} onClick={onOpen}>
      <Icon>{script.meta.icon || "send"}</Icon>
    </Button>
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}  aria-labelledby="info-dialog-title" aria-describedby="info-dialog-description">
      <DialogTitle >
        <Typography variant="h6" color="inherit" style={{ flex: 1 }}>Script Source</Typography>
        <IconButton color="inherit" sx={classes.closeButton} onClick={onClose} aria-label="Close"> <CloseIcon /> </IconButton>          
      </DialogTitle>
      <DialogContent style={{  minWidth: 800 }} dividers>
        <TextField type={"text"} fullWidth={true} multiline minRows={20} variant="standard" value={value} onChange={onChange}></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} label="Cancel">
          <Icon>cancel</Icon>
        </Button>
        <CommandButton
          path={`controllers/${uuid}/execute/debug`}
          onSuccess={onSuccess}
          onSend={onClose}
          params={{script: value}}
          message={`Script debug scheduled successfully`}>
          <Button label="Run" color="primary"  >
            <Icon>send</Icon>
          </Button>
        </CommandButton>
      </DialogActions>
    </Dialog>
  </>

}