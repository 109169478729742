import React, { useState } from 'react';
import {  MenuItemLink, MenuProps, Translate, usePermissions, UserIdentity, useSidebarState, useTranslate } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings"
import SubMenu from './SubMenu';
import { compact,  map } from "lodash"
import { useAccount } from "../hooks"
import { items, MenuItemConfig } from "../config/menu"
import { checkPermissions } from '../lib/helper';
import { MouseEventHandler } from 'react';
import { Box } from '@mui/material';

function renderItems(account: UserIdentity, permissions: string[], items: MenuItemConfig[], onMenuClick: MouseEventHandler, translate: Translate) {
  return account ? compact(items.map(item => (checkPermissions(permissions, ...item.permissions) ? <MenuItemLink
      key={item.name}
      to={`/${item.name}`}
      primaryText={item.title}
      leftIcon={item.icon}
      onClick={onMenuClick}
    /> : null
  ))) : []
}

export default function MyMenu({ onMenuClick, logout, ...props }: MenuProps) {
  const translate = useTranslate()
  const [sidebarIsOpen] = useSidebarState()
  
  const [isOpen, setIsOpen] = useState(false)
  const { permissions } = usePermissions()
  const account = useAccount()
  const roles = map(permissions, "role")
  const configItems = renderItems(account, roles, items.filter(item => item.group === "config"), onMenuClick, translate)
  return <Box sx={{paddingTop: 1}}>
    {renderItems(account, roles, items.filter(item => item.group === undefined), onMenuClick, translate)}
    {configItems.length > 0 &&
      <SubMenu
        handleToggle={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        sidebarIsOpen={sidebarIsOpen}
        name="titles.config"
        icon={<SettingsIcon />}
      ><>{configItems}</>
      </SubMenu>
    }
  </Box>
}
