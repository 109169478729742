import React, { useState, useEffect, useRef, useCallback, CSSProperties, HTMLAttributes } from 'react';
import { Tabs, Tab, Paper,  CssBaseline, TabProps, styled } from '@mui/material';
import { SignalCellular4Bar as IconConnected, SignalCellular0Bar as IconDisconnected, } from '@mui/icons-material';
import ioClient, { Socket } from "socket.io-client"
import Devices from './gpio/Devices';
import GpioStatePlaceholder from './gpio/GpioStatePlaceholder';
import LogEvents from './state/LogEvents';
import RpiBoard from './gpio/RpiBoard';
import GlobalState from './state/GlobalState';
import ConfigView from './config/ConfigView';
import XTerminal from './XTerminal';
import { Button } from '@mui/material';
import { Icon } from '@mui/material';
import CommandButton from '../../../components/CommandButton';
import { Notification } from 'ra-ui-materialui';
import { Memory, List, Settings } from '@mui/icons-material';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import SystemConfig from './system/SystemConfig';
import { useNavigate } from 'react-router';
import { useScopedStorageState } from '../../../lib/storage';
import { useDeepCompareEffect, usePermissions } from 'react-admin';
import { DeviceGlobalState, GpioState, MonitoringConfig, MonitoringEvent, MonitoringHardwareState, MonitoringSystemState } from '../../../lib/types';
import { isApplicationAdmin, isSuperAdmin } from '../../../lib/helper';


const server = "https://webtty.icacs.io"

function addEvent(events: MonitoringEvent[], event: MonitoringEvent) {
  if (event.source === "icacs:monitoring" && event.action === "devices") {
    return events
  }
  event.createdAt = (new Date()).toISOString()
  event.args = event.args ?? event.data
  delete event.data
  events.unshift(event)
  events = events.slice(0, 2000)
  return events;
}
function getTab(): number {
  const url = new URL(document.location.href)
  return parseInt(url.searchParams.get("tab") ?? "0") ?? 0
}
const MontioringRoot = styled('div')({
  height: "100%",
  paddingLeft: 5,
  paddingRight: 5,
  marginLeft: 0,
  marginRight: 0,
  
  "& > .content": {
    flexGrow: 1,    
    overflow: "auto",    
  },
  "& > .content100": {
    height: "100%",
    width: "100%",
    overflow: "hidden",    
  },
  "& .head": {
    display: 'flex',
    flexDirection: 'row',
    alignItems: "center"
  },
  "& .actions": {
    marginRight: 10,
    "&> span button": {
      marginLeft: 10
    },
    "&> button": {
      marginLeft: 10
    }
  },
  "& .container": {
    paddingTop: 5,
    paddingBottom: 20,
  },
  "& .paper": {
    padding: 10,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  "& .main": {
    display: 'flex',
    justifyContent: "space-between"
  },
  "& .events": {
    display: 'flex',
    marginTop: 20,
    width: "100%"
  }
});

interface ExpandButtonProps {
  onExpand: () => void
}
interface StartButtonProps {
  uuid?: string, 
  token: string, 
  connected: boolean
}
interface TabContentProps extends HTMLAttributes<HTMLDivElement> {
  tab: number
  activeTab: number
}
interface TabHeaderProps extends TabProps {
  label: string
  icon: React.ReactElement
}
interface MonitoringProps {
  uuid: string,
  ApplicationUuid: string,
  token: string,
  onExpand?: () => void
}
class SocketExt extends Socket {
  time: number = 0
}

function ExpandButton({ onExpand }: ExpandButtonProps) {
  return <Button variant="outlined" color="primary" onClick={onExpand}>
    <Icon>fullscreen</Icon>
  </Button>
}
function StartButton({ uuid, token, connected }: StartButtonProps) {
  const icon = connected ? <IconConnected color="primary" /> : <IconDisconnected />
  return !connected && uuid ? <CommandButton 
      path={`controllers/${uuid}/execute/monitoring`} 
      params={{ sessionToken: token }} 
      message={`Monitoring has been started`}>
    <Button variant="outlined" color="primary" startIcon={icon} >Start</Button>
  </CommandButton> : <Button variant="outlined" color="primary" >{icon}</Button>
}
function TabContent({ tab, activeTab, children, ...props }: TabContentProps) {
  const style: CSSProperties = tab === activeTab ? {
      width: "100%",
      height: "100%",
      display: "block",
      position: "relative",
  } : {display: "none"}
  return <div style={style} {...props}>{children}</div>
}
function TabHeader({ label, icon, ...props }: TabHeaderProps) {
  const content = <span style={{display: "inline-flex" }}>{icon}&nbsp;{label}</span>
  return <Tab label={content} {...props}  sx={{
    // "& > span:first-child": {
    //   flexDirection: "row"
    // },
  }} />      
}

export default function Monitoring({ uuid, ApplicationUuid, token, onExpand }: MonitoringProps) {
  const navigate = useNavigate()
  const { permissions } = usePermissions()  
  const _isAdmin = isApplicationAdmin(permissions, ApplicationUuid)
  const _isSuperAdmin = isSuperAdmin(permissions)


  const actionsRef = React.useRef<HTMLSpanElement>(null);
  const [socketLoaded, setSocketLoaded] = useState(false)  
  const [initialized, setInitialized] = useState(false)
  
  const [gpio, setGPIO] = useState<GpioState>({})
  const [systemState, setSystemState] = useState<MonitoringSystemState>(  )
  const [hardwareState, setHardwareState] = useState<MonitoringHardwareState>()
  
  const [state, setState] = useState<DeviceGlobalState>({})
  const [events, setEvents] = useScopedStorageState<MonitoringEvent[]>("monitoring.events", token, [])
  const [config, setConfig] = useState<MonitoringConfig>({  })
  const [connected, setConnected] = useState(false)
  const [activePins, setActivePins] = useState<number[]>([])  
  const [tab, _setTab] = useState<number>(getTab())
  const ref = useRef<SocketExt>()

  const setTab = useCallback((tab: number) => {    
    const url = new URL(document.location.href)
    url.searchParams.set("tab", tab.toString())
    navigate(url.pathname + url.search)
    _setTab(tab)
  }, [_setTab, navigate])
  
  const onPinClick = (num: number) => setActivePins(activePins.indexOf(num) > -1 ? [] : [num])
  

  useEffect(() => {
    const socket = ref.current = ioClient(server, { path: `/mb/consumers/gpio/${token}` }) as SocketExt
    setSocketLoaded(true)

    const interval = setInterval(() => {
      setConnected((new Date().valueOf() - socket.time) < 60000)
      socket.emit("ping")
    }, 15000)
    
    socket.onAny(() => setConnected(true))
    socket.on("connect", () => socket.emit("ping"))
    socket.on("disconnect", () => setConnected(false))

    return () => {
      clearInterval(interval)
      setInitialized(false)
      socket.disconnect()
    }
  }, [setSocketLoaded, setConnected, token])

  
  useDeepCompareEffect(() => {
    const socket = ref.current
    if (!initialized && socket) {
      socket.on("event", data => setEvents(addEvent(events, data)))
      socket.on("state", data => setState(data))
      socket.on("config", data => setConfig(data))
      socket.on("gpio", config => setGPIO(config))
      
      socket.emit("config")
      socket.emit("gpio")
    }     
  }, [ref, socketLoaded, initialized,  setEvents, setState, setConfig, setGPIO, events])

  useEffect(() => {
    const socket = ref.current
    if (!initialized && socket) {
      socket.on("systemState", state => setSystemState(state))
      socket.on("hardwareState", state => setHardwareState(state))      
      socket.emit("systemState")
      socket.emit("hardwareState")
    }     
  }, [ref, socketLoaded, initialized, setHardwareState, setSystemState])
  
  useEffect(() => {
    setInitialized(true)
  }, [setInitialized, initialized])
  
  const devicesLoaded = config && config.hardware 
  const TABS = {
    GPIO: 0,
    EVENTS: 1,
    CONFIG: 2,
    SYSTEM: 3,
    SSH: 4
  }
  return (
    <MontioringRoot>
      <CssBaseline />
      {!uuid && <Notification autoHideDuration={3000} />}
      <div className={tab === TABS.SSH ? "content100" : "content"}>
        <div className={"head"}>         
          <Tabs value={tab} onChange={(event, tab) => setTab(tab)} sx={{ flexGrow: 1 }}>
            <TabHeader value={TABS.GPIO} label="GPIO" icon={<Memory />}/>
            <TabHeader value={TABS.EVENTS} label="Events"  icon={<List />} />
            {_isSuperAdmin && <TabHeader value={TABS.CONFIG} label="Config" icon={<Settings />} />}            
            <TabHeader value={TABS.SYSTEM} label="System" icon={<DeveloperBoardIcon />} />
            {_isAdmin && <TabHeader value={TABS.SSH} label="SSH" icon={<Icon>terminal</Icon>}/>}
          </Tabs>
          <div className={"actions"}>
            <span ref={actionsRef}></span>
            {onExpand && <ExpandButton onExpand={onExpand} />}
            <StartButton uuid={uuid} token={token} connected={connected} />
          </div>
        </div>
        <TabContent tab={TABS.GPIO} activeTab={tab}>
          <Paper className={`paper main`} style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            {devicesLoaded && <Devices gpio={gpio} config={config} onPinClick={onPinClick} socketRef={ref} />}
            {!devicesLoaded && <GpioStatePlaceholder onPinClick={onPinClick} />}
            <div>
              <RpiBoard gpio={gpio} activePins={activePins} socketRef={ref} readonly={!_isAdmin}/>
            </div>
          </Paper>
          <div className={"events"} >
            <Paper className={"paper"} style={{ width: "100%" }}>
              <LogEvents events={events} filter={["gpio"]}/>
            </Paper>
          </div>
        </TabContent>
        <TabContent tab={TABS.EVENTS} activeTab={tab}>
          <div style={{display: "flex"}}>
            <Paper className={"paper"} style={{ flexGrow: 1 }}>
              <LogEvents events={events} />
            </Paper>            
            <Paper className={"paper"} style={{marginLeft: 10, width: 400 }}>
              <GlobalState state={state} /> 
            </Paper>            
          </div>        
        </TabContent>
        {_isSuperAdmin && <TabContent tab={TABS.CONFIG} activeTab={tab}>
          <ConfigView config={config} actionsContainer={actionsRef} onChange={setConfig} visible={tab === TABS.CONFIG} socketRef={ref} />
        </TabContent>}        
        <TabContent tab={TABS.SYSTEM} activeTab={tab}>
          <SystemConfig systemState={systemState} hardwareState={hardwareState} restrictions={config?.restrictions} socketRef={ref} actionsContainer={actionsRef} visible={tab === TABS.SYSTEM} readonly={!_isAdmin} /> 
        </TabContent>
        {_isAdmin && <TabContent tab={TABS.SSH} activeTab={tab} >
          <XTerminal token={token} visible={tab===TABS.SSH} />
        </TabContent>}  
      </div>
    </MontioringRoot>
  )
}
