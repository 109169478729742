
import { usePageTitle } from '../../hooks';
import Monitoring from './components/Monitoring';
import {  useParams } from 'react-router';
import {  useGetOne } from 'react-admin';
export default function MonitoringPage () {
  usePageTitle("Debug")
  const params = useParams()
  const { data,  } = useGetOne("controllers", { id: params.uuid as string })  
  return <Monitoring token={params.token as string} uuid={params.uuid as string} ApplicationUuid={data?.ApplicationUuid}/>
}
