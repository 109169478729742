import React, { useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { isArray, compact, intersection, find, omit, mapValues,   } from "lodash"

import { Chip, styled } from '@mui/material';
import EventIcon from './EventIcon';
import { MonitoringEvent } from '../../../../lib/types';

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
const LogRoot = styled("div")({
  width: '100%',
  marginTop: 10,
  "& .paper": {
    width: '100%',
    marginBottom: 20,
  },
  "& .table": {
    minWidth: 750,
  },
  "& .tableWrapper": {
    overflowX: 'auto',
  }
})
type DisplayEvent = {
  source: string,
  target: string,
  action: string,
  args: string
  options: any,
  response: any,
  createdAt: string
}
function smartEvent(event: MonitoringEvent): DisplayEvent {
  const { source, target, action, args, options, response, createdAt } = event
  if (action === "deviceCommand") {
    return ({
      ...event,
      action: [args.name, args.action].join(":"),
      args: args.args
    })
  }
  
  return ({
    source, 
    target, 
    action: [event.target, event.action].join(":"), 
    args: event.action === "configure" ? mapValues(event.args, () => "{...}"): args, 
    options, 
    response, 
    createdAt
  })  
}
function smartResponse(event: DisplayEvent, response: any) {
  switch (event.action) {
    case "activator:devices": 
      return isArray(response) ? "[...]" : JSON.stringify(response); 
    case "activator:monitoring": 
      return "..."
    default:
      return JSON.stringify(response)
  }  
}
function convertEvent(event: MonitoringEvent, events: MonitoringEvent[]) {
  const { source = "unknown", target = "unknown", method, createdAt, response, args, options } = event 
  if (method === "deviceAvailable") {
    return undefined
  }
  if (UUID_REGEX.test(method)) {    
    const sourceEvent = find(events, {uuid: method})
    if (sourceEvent) {
      sourceEvent.response = args.error ?? args.response
    }
    return undefined
  }
 
  return smartEvent({
    source: source.replace(/^icacs:(\w+)(-driver|-service){0,1}$/, "$1"),
    target: target.replace(/^icacs:(\w+)(-driver|-service){0,1}$/, "$1"),
    action: method,
    args,
    response,
    createdAt: createdAt.split("T")[1].replace("Z", ""),
    options: omit(options, "timeout", "callbackId"),
    
    uuid: '',
    method,    
  })
}
interface LogEventsProps {
  events: MonitoringEvent[], 
  filter?: string[]
}
export default function LogEvents({ events, filter = [] }: LogEventsProps) {
  const [perPage, setPerPage] = useState(100)
  const [page, setPage]       = useState(0)

  const handleChangePage = (event: any, newPage: number) => setPage(newPage)
  const handleChangeRowsPerPage = (event: any) => setPerPage(+event.target.value)
  
  const filterFn = ({ source, target }: {source: string, target: string}) => filter.length === 0 || intersection(filter, [source, target]).length > 0
  const rows = compact(events.map((event: any, index: number) => convertEvent(event, events.slice(index)))).filter(filterFn)

  return (<LogRoot >
    <div style={{ overflowX: 'auto' }}>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small" >
        <TableHead>
          <TableRow>
            <TableCell>Source</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Args</TableCell>
            <TableCell>Options</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * perPage, page * perPage + perPage)
            .map((event, index) => {
              return (
                <TableRow hover tabIndex={-1} key={`ev${index}`}>
                  <TableCell>
                    <Chip variant="outlined" icon={<EventIcon type={event.source}  />} label={event.source} />
                  </TableCell>
                  <TableCell>
                    <Chip variant="outlined" icon={<EventIcon type={event.action} />} label={event.action} />
                  </TableCell>
                  <TableCell sx={{maxWidth: "400px"}}>{JSON.stringify(event.args)}</TableCell>
                  <TableCell sx={{ maxWidth: "400px" }}>{JSON.stringify(event.options)}</TableCell>
                  <TableCell >{smartResponse(event, event.response)}</TableCell>
                  <TableCell sx={{align: "left"}}>{event.createdAt}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
    <TablePagination
      rowsPerPageOptions={[50, 100, 200]}
      component="div"
      count={rows.length}
      rowsPerPage={perPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </LogRoot>)
}
