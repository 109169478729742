import React, { useCallback } from "react"
import TokenIcon from '@mui/icons-material/Fingerprint';
import { Button } from "ra-ui-materialui"
import {useNotify, useDataProvider, useRecordContext} from "ra-core"

export default function GenerateTokenButton(){
  const dataProvider = useDataProvider()
  const record = useRecordContext()
  const notify = useNotify()
  const handleClick = useCallback(() => {
    dataProvider.execute(`applications/${record.id}/createApiToken`, {})
      .then(({ data }: {data: any}) => notify(data.token, {type: "info"}))
      .catch((error: any) => notify(error.message, {type: "error"}))
  }, [dataProvider, record.id, notify])
  return record && record.id ?
    <Button color="primary" onClick={handleClick} label="Generate Token"  ><TokenIcon /></Button>
    : <span />
}