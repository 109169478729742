// in src/posts.js
import React, { useState, useCallback } from 'react';
import ParamsInput from "./components/ParamsInput"
import { FormTab, TabbedForm, TextInput, NumberInput, AutocompleteInput} from 'ra-ui-materialui';
import { Icon } from '@mui/material';
import { makeStyles } from '../../lib/makeStyles';
import { useRecordContext } from 'react-admin';
import { Edit } from '../../components/DefaultDetails';
import icons from '../../lib/icons';

const iconsChoices = icons.map(icon => ({
  id: icon, name: icon
}))
const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    width: 250
  }
}))
export default function NamedScriptsEdit(){
  const classes         = useStyles()
  const record          = useRecordContext()
  const [icon, setIcon] = useState(record?.meta?.icon ?? "")
  
  const onSelect = useCallback((ev: any) => {
    if (ev && ev.highlightedIndex) {
      setIcon(iconsChoices[ev.highlightedIndex].id)
    }    
  }, [setIcon])

  return <Edit titleSource="name" >
    <TabbedForm >
      <FormTab label="Summary"  >
        <TextInput source="name" />
        <TextInput source="description" />
        <NumberInput source="priority" />
        <TextInput multiline fullWidth source="content" rows={20}   />
      </FormTab>
      <FormTab label="Params"  >
        <ParamsInput source="params" label="Params" />          
      </FormTab>
      <FormTab label="Icon"  >
        {icon && <>
          <Icon>{icon}</Icon>
        </>}
        <AutocompleteInput label="Icon" source="meta.icon" choices={iconsChoices} classes={classes} suggestionLimit={20}
          onSelect={onSelect} onChange={setIcon}
        /> 
      </FormTab>
    </TabbedForm>
  </Edit>
}
