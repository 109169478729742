import React, { ReactElement } from 'react';
import { useCreatePath, Link, LinkProps, useResourceContext, useRecordContext } from 'react-admin';
import get from "lodash/get"


interface ShowLinkProps extends Omit<LinkProps, "to"> {
  source: string
}
export default function ShowLink({ source, children, className, ...rest }: ShowLinkProps) {
  const resource = useResourceContext(rest)
  const record = useRecordContext()
  const createLink = useCreatePath()
  const url = createLink({ resource, type: "show", id: record.id })
  return <Link className={className} {...rest} to={url}>
    {children && React.cloneElement(children as ReactElement, { record, source})}
    {!children && source && get(record, source)}
  </Link>
}
