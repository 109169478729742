import React from 'react';
import { SimpleForm, DateTimeInput, TextInput, ArrayInput, SimpleFormIterator, Toolbar, SaveButton } from "ra-ui-materialui";
import { useDataProvider, useNotify } from 'ra-core';
import { makeStyles } from '../../../lib/makeStyles';


const useStyles = makeStyles(theme => ({
  fields: {
    display: "flex",
    alignItems: "baseline",
    "& .RaSimpleFormIterator-form": {
      display: "flex",
      flexDirection: "row",
      "& .ra-input": {
        marginRight: 10,
        display: "flex",
      },
      "& .RaSimpleFormIterator-line": {
        borderBottom: "none",
      }
    }
  },
  fieldsActions: {
    display: "flex",
    padding: 0
  },
  fieldsInput: {
    width: theme.spacing(24),
    marginRight: 5,
  },

}))

export default function ApiTokens(){
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const notify = useNotify()
  const onSave = (values: any) => {
    dataProvider
      .update("applications", {
        id: values.id,
        data: values,
        previousData: undefined
      })
      .then(() => notify("Updated", {type: "success"}))
      .catch(error => notify(error.message, {type: "error"}))
  }
  return <SimpleForm resource="applications" onSubmit={onSave} toolbar={<Toolbar ><SaveButton /></Toolbar>} >
    <TextInput source="callbackUrl" fullWidth />
    <ArrayInput source="apiTokens" margin="normal" >
      <SimpleFormIterator disableAdd sx={classes.fields}>
        <TextInput label="Name" source="name" sx={classes.fieldsInput} />
        <DateTimeInput label="Created" source="createdAt"  disabled sx={classes.fieldsInput} />
        <DateTimeInput label="Updated" source="updatedAt"  disabled sx={classes.fieldsInput} />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
}
