import React, {  ReactElement } from 'react';
import AdminsIcon from '@mui/icons-material/Group';
import ApplicationsIcon from '@mui/icons-material/Home';
import ControllersIcon from '@mui/icons-material/Smartphone';
import PackagesIcon from '@mui/icons-material/Apps';
import ScriptsIcon from "@mui/icons-material/Description"
import { CloudUpload } from '@mui/icons-material';

export type MenuItemConfig = {
    name: string,
    title: string,
    permissions: string[],
    icon: ReactElement
    group?: string
}
const items: MenuItemConfig[] = [
    { name: 'controllers', permissions: ["root", "admin", "viewer"], icon: <ControllersIcon />, title: "Controllers" },
    { name: 'packages', permissions: ["root"], icon: <PackagesIcon />, title: "Packages" },
    { name: 'admins', permissions: ["root", "admin"], icon: <AdminsIcon />, title: "Admins", },
    { name: 'applications', permissions: ["root"], icon: <ApplicationsIcon />, title: "Applications", group: "config" },
    { name: 'named-scripts', permissions: ["root"], icon: <ScriptsIcon />, title: "Scripts", group: "config" },
    { name: 'firmware', permissions: ["root", "admin", "viewer"], icon: <CloudUpload />, title: "Downloads", },
]
export { items }
