import React, { useEffect } from 'react';
import ioClient, { Socket } from "socket.io-client"
import { useRef } from 'react';
import { FitAddon } from 'xterm-addon-fit';
import { useLayoutEffect } from 'react';
//import XTerm from "../../../components/XTerm"
import { styled } from '@mui/material';
import 'xterm/css/xterm.css'
import { Terminal } from 'xterm'
declare global {
  interface Window {
    fit: any
    
  }
}
const server = "https://webtty.icacs.io"

const XTermRoot = styled("div")({
  display: "block",
  width: "100%",
  height: "calc(100% - 50px)",
  minHeight: 500,
})

class DivExt extends HTMLDivElement {
  term?: Terminal
  socket?: Socket
  initialized: boolean = false
  buf: string = ""
}
interface XTerminalProps {
  token: string,
  visible?: boolean
}

export default function XTerminal({ token, visible = true }: XTerminalProps) {
  const xtermRef = useRef<DivExt>(null)  
  const fitAddon = useRef(new FitAddon())
  
  useLayoutEffect(() => {
    if (xtermRef.current && !xtermRef.current.term) {
      const term = xtermRef.current.term = new Terminal({
        drawBoldTextInBrightColors: false,
        rendererType: "canvas",
        fontSize: 15,
        fontFamily: "DejaVu Sans Mono, Noto Sans Mono, Everson Mono, FreeMono, Menlo, Terminal, monospace"
      })
      term.open(xtermRef.current)
      term.loadAddon(fitAddon.current)
      window.fit = fitAddon.current
      window.addEventListener("resize", () => {
        fitAddon.current.fit()
      })
      term.onResize(({ cols, rows }: {cols: number, rows: number}) => {
        if (xtermRef.current?.socket)
          xtermRef.current.socket.emit("resize", { col: cols, row: rows })
      })

      term.onData((data: string) => {
        if (xtermRef.current?.socket)
          xtermRef.current.socket.emit("input", data)
      })
      term.onTitleChange((title: string) => document.title = title)
    }
  }, [xtermRef]) 

  useLayoutEffect(() => {
    const socket = xtermRef.current?.socket
    if (xtermRef.current && !socket) {
      const socket = xtermRef.current.socket = ioClient(server, { path: `/mb/consumers/webtty/${token}` })
      socket.on("output", data => {
        const term = xtermRef.current?.term
        if (xtermRef.current && !term) {
          xtermRef.current.buf += data;
          return
        }
        term?.write(data)
      })
    }
    return () => { socket?.disconnect() }
  }, [token, xtermRef])

  useEffect(() => {
    if(visible) {
      setTimeout(() => {
        const fit = fitAddon.current
        fit.fit()
        const size = fit.proposeDimensions();
        if (size && xtermRef.current?.socket) {
          xtermRef.current?.socket.emit("resize", { col: size.cols, row: size.rows })
        } 
      }, 100)      
    }

  }, [visible])
  
  return <XTermRoot ref={xtermRef} />    
}
