import { createTheme } from '@mui/material/styles';
const defaultTheme = createTheme({
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "background": {
      "paper": "#fff",
      "default": "#fafafa"
    },
    "primary": {
      "light": "rgba(80, 227, 194, 1)",
      "main": "rgba(43, 109, 93, 1)",
      "dark": "rgba(37, 150, 155, 1)",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "#ff4081",
      "main": "rgba(139, 54, 83, 1)",
      "dark": "#c51162",
      "contrastText": "#fff"
    },
    "error": {
      "light": "#e57373",
      "main": "#f44336",
      "dark": "#d32f2f",
      "contrastText": "#fff"
    },
    "text": {
      "primary": "rgba(0, 0, 0, 0.87)",
      "secondary": "rgba(0, 0, 0, 0.54)",
      "disabled": "rgba(0, 0, 0, 0.38)",
    }
  },
  components: {
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       minWidth: "250px"
    //     }
    //   }
    // },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: "100%"
        }
      }      
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          overflowWrap: "anywhere"
        }
      }      
    },
  },
  
});
export default defaultTheme