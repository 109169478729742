import React, { MouseEvent, MouseEventHandler, useCallback } from 'react';
import { Link, Button, useAuthProvider, useLogout } from "react-admin"
import ProfileIcon from "@mui/icons-material/Person"
import LogoutIcon from "@mui/icons-material/PowerSettingsNew"
import RemoveIcon from '@mui/icons-material/Delete';
import ProfileImage from "./ProfileImage"

import { Card, CardContent, Theme, Typography, useTheme } from '@mui/material';
import { Profile } from '../../lib/authProvider';

const classes = ({
  card: {
    display: 'flex',
    width: 300,
  },
  cardActive: {
    display: 'flex',
    width: 300,
    backgroundColor: "#EEE"
  },
  cover: {
    margin: "10px 10px",
    width: 64,
    height: 64,
  },  
  user: {
    whiteSpace: "normal"
  },
  content: {
    flex: '1 0 auto',
    justifyContent: "flex-start",
  },
  controls: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    justifyContent: "flex-end",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  })
})

type Unset = (profile: Profile) => MouseEventHandler<Element>
export default function UserCard({ account, profile }: { account: Profile, profile: Profile }) {
  const authProvider = useAuthProvider()
  const logout = useLogout()
  const theme = useTheme()

  const active = account && account.id === profile.id
  const unset: Unset = useCallback((profile: Profile) => (event: MouseEvent<Element>) => {
    event.stopPropagation()
    authProvider.removeAccount(profile)
  }, [authProvider])


  return <Card sx={active ? classes.cardActive : classes.card} elevation={1} >
    <ProfileImage profile={profile} variant="profile" />
    <div style={{ display: 'flex', flexDirection: 'column', width: "70%" }}>
      <CardContent sx={classes.content}>
        <Typography component="h5" variant="subtitle1" color={active ? "primary" : "inherit"}>{profile.fullName}</Typography>
        <Typography sx={classes.user} variant="subtitle1" color="textSecondary">Admin</Typography>
      </CardContent>
      <div style={classes.controls(theme)}>
        {active && <Link to="/profile" >
          <Button label="titles.profile"><ProfileIcon /></Button>
        </Link>}
        {active && <Button label="titles.logout" onClick={logout}>
          <LogoutIcon />
        </Button>}
        {!active && <Button label="titles.remove" onClick={unset(profile)}>
          <RemoveIcon />
        </Button>}
      </div>
    </div>
  </Card>
}
