// in src/posts.js
import React from 'react';
import { CreateProps } from 'ra-ui-materialui';
import { Create } from '../../components/DefaultDetails';
import AdminForm from "./components/AdminForm"

export default function AdminCreate(props: CreateProps) {
  return <Create {...props} >
    <AdminForm />
  </Create>
}
