import React, { useCallback } from 'react';
import { useDataProvider, useNotify } from 'ra-core';

export interface CommandButtonProps {
  children: React.ReactElement
  path: string,
  message?: string,
  params?: any,
  onSend?: () => void,
  onSuccess?: () => void,
}
export default function CommandButton({ children, path, message, params, onSend, onSuccess, ...props }: CommandButtonProps){
  const dataProvider = useDataProvider()
  const notify       = useNotify()
  const displayMessage = useCallback(() => message && notify(message, {type: "success"}), [notify, message])
  const onScriptClick = useCallback((params: any) => {
    const data = typeof (params) === "function" ? params() : params    
    dataProvider.execute(path, data ?? {}).then(() => {
      displayMessage()
      onSuccess && onSuccess()
    })  
    onSend && onSend()
  }, [dataProvider, path, onSend, onSuccess, displayMessage])
  
  return React.cloneElement(children, { onClick: () => onScriptClick(params), ...props})
}
