import React, { useState, useCallback, ReactElement } from 'react';
import { Button, RaRecord, RecordContext, SaveButton, SaveContextProvider, SaveHandler, Toolbar } from "react-admin"
import { useTranslate, useCreate, useNotify } from "react-admin"

import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';


const ExternalToolbar = ({ saving, onClose }: {saving: boolean, onClose: () => void}) => {
  return <Toolbar >
    <SaveButton saving={saving} />
    <Button label="ra.action.cancel" onClick={onClose}>
      <IconCancel />
    </Button>
  </Toolbar>
}
export interface QuickCreateButtonProps {
  resource: string
  title?: string 
  label?: string
  defaultValues?: any
  onSubmit?: () => void
  children: ReactElement
}
export default function QuickCreateButton({ children, defaultValues, resource, label, onSubmit, title }: QuickCreateButtonProps) {
  const translate = useTranslate()
  const notify = useNotify()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [create, { isLoading, error }] = useCreate(resource, { data: defaultValues })
  if (error) {
    notify((error as Error).message, {type: "error"}) 
  }
  const open = useCallback(() => setDialogOpen(true), [setDialogOpen])
  const close = useCallback(() => setDialogOpen(false), [setDialogOpen])

  const handleSubmit: SaveHandler<RaRecord> = async (record: Partial<RaRecord>) => {
    close()
    create(resource, { data: record }, { onSuccess: onSubmit })
  }
  const dialogTitle = translate(title || "titles.create", { resource: translate(`resources.${resource}.name`, { smart_count: 1 }) })
  return (
    <>
      <Button onClick={open} label={label || dialogTitle}>
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={dialogOpen} onClose={close} >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>          
            <SaveContextProvider value={{save: handleSubmit, saving: isLoading, mutationMode: "pessimistic"}}>
              <RecordContext.Provider value={defaultValues}>
                {React.cloneElement(children, { toolbar: <ExternalToolbar saving={isLoading} onClose={close} /> })}
              </RecordContext.Provider>
            </SaveContextProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}
