import React, { useState, useCallback, ReactElement } from 'react';
import { Button, RaRecord, RecordContext, SaveButton, SaveContextProvider, SaveHandler, Toolbar, useRecordContext } from "react-admin"

import { useTranslate, useUpdate, useNotify } from "react-admin"

import IconContentCreate from '@mui/icons-material/Create';
import IconCancel from '@mui/icons-material/Cancel';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

const ExternalToolbar = ({ saving, onClose }: { saving: boolean, onClose: () => void }) => {
  return <Toolbar >
    <SaveButton saving={saving} />
    <Button label="ra.action.cancel" onClick={onClose}>
      <IconCancel />
    </Button>
  </Toolbar>
}
export interface QuickEditButtonProps {
  resource: string
  title?: string
  label?: string
  defaultValues?: any
  onSubmit?: () => void
  children: ReactElement
}

export default function QuickEditButton({ children, defaultValues,  resource, label, onSubmit, title }: QuickEditButtonProps) {
  const record = useRecordContext()
  const translate = useTranslate()
  const notify = useNotify()
  const [update, { isLoading, error }] = useUpdate(resource, { id: record.id, data: defaultValues } )
  if (error) {
    notify((error as Error).message, {type: "warning"})
  }

  const [dialogOpen, setDialogOpen] = useState(false)

  const open = useCallback(() => setDialogOpen(true), [setDialogOpen])
  const close = useCallback(() => setDialogOpen(false), [setDialogOpen])

  const handleSubmit: SaveHandler<RaRecord> = async (data: Partial<RaRecord>) => {
    close()
    update(resource, { id: record.id, data: data }, { onSuccess: () => {
      close()
      onSubmit && onSubmit()
    }})
  }

  const dialogTitle = translate(title || "ra.action.edit", { resource: translate(`resources.${resource}.name`, { smart_count: 1 }) })
  return (
    <>
      <Button onClick={open} label={label || dialogTitle}>
        <IconContentCreate />
      </Button>
      <Dialog fullWidth open={dialogOpen} onClose={close} >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <SaveContextProvider value={{ save: handleSubmit, saving: isLoading, mutationMode: "pessimistic" }}>
            <RecordContext.Provider value={record}>
              {React.cloneElement(children, { toolbar: <ExternalToolbar saving={isLoading} onClose={close} /> })}
            </RecordContext.Provider>
          </SaveContextProvider> 
        </DialogContent>
      </Dialog>
    </>
  )
}
