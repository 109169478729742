// in src/App.js
import React, { useEffect, useState } from "react"
import { ThemeProvider, } from '@mui/material/styles';
import { createBrowserHistory } from 'history';
import config from "../src/config"
import i18nProvider from "./config/i18n"
import resources  from "./config/resources"
import defaultTheme from "./theme";
import { Admin, Loading, NotFound } from "react-admin";
import dataProvider from "./lib/dataProvider";
import authProvider from "./lib/authProvider"
import MyLayout from "./layout/Layout";
import { applyDefaults } from "./config/defaults";

const history = createBrowserHistory()

applyDefaults()

export default function MyApp() {
  const [token, setToken] = useState(authProvider.getToken() || "guest")

  useEffect(() => {
    authProvider.addListener("token", setToken)
    return () => authProvider.removeListener("token", setToken)
  }, [setToken])

  return <ThemeProvider theme={defaultTheme}>
    <Admin key={token} title={"IMAMS Admin"}
      theme={defaultTheme}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider(config.apiUrl)}
      history={history}
      layout={MyLayout}
      //loginPage={LoginPage}
      loading={Loading}
      catchAll={NotFound}      
    >{resources}
    </Admin>
  </ThemeProvider>
}
