import React, { CSSProperties, ReactElement, useCallback, useState } from 'react';
import ActionDelete from '@mui/icons-material/Delete';
import { Confirm, Button, ConfirmProps, Identifier, useRecordContext, useResourceContext, useTranslate } from "react-admin"
import { useDelete, useNotify } from 'react-admin';
import { Theme } from '@mui/material/styles';
import { alpha } from "@mui/material"

const classes: Record<string, (theme: Theme) => CSSProperties> = {
  deleteButton: (theme: Theme) => ({
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      '@media (hover: none)': {
        backgroundColor: "transparent",
      }
    }
  })
}
interface DeleteWithConfirmButtonProps extends Omit<ConfirmProps, "content" | "title" | "onClose" | "onConfirm"> {
  label?: string
  onDelete?: (id: Identifier) => void
  onSuccess?: () => void
  icon?: ReactElement  
  title?: string
}

const QuickDeleteButton = ({ title, icon = <ActionDelete />, label = 'ra.action.delete', onDelete, onSuccess, ...props }: DeleteWithConfirmButtonProps) => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const translate = useTranslate()
  const notify = useNotify()
  const [open, setOpen] = useState(false)
  const [destroy, { isLoading, error }] = useDelete(resource, { id: record.id })
  const doOpen = useCallback(() => setOpen(true), [setOpen])
  const doClose = useCallback(() => setOpen(false), [setOpen])

  const onDeleteSuccess = useCallback(() => {
    notify("Element deleted", {type: "success"})
    onSuccess && onSuccess()
  }, [onSuccess, notify]) 
  const doConfirm = useCallback(() => {
    doClose()
    if (record.id) {
      if (onDelete) {
        onDelete(record.id)
      } else {
        destroy(resource, { id: record.id }, { onSuccess: onDeleteSuccess })
      }
    }
  }, [doClose, resource, onDeleteSuccess, onDelete, record.id, destroy])

  if (!record)
    return <span />
  if (error) {
    notify((error as Error).message, { type: "error" })
  }
  const deletable = record.deletable !== false
  return (<>
    {deletable && <Button onClick={doOpen} disabled={isLoading} label={label} className="ra-delete-button" sx={classes.deleteButton} >            
      {icon}
    </Button>}
    <Confirm isOpen={open} 
      {...props}
      title={title ?? translate("ra.message.delete_title", { name: resource, id: record.id })}
      content={translate("ra.message.delete_content")}      
      onConfirm={doConfirm}
      onClose={doClose}      
    />
  </>
  )
}

export default QuickDeleteButton
