import { HttpError } from "react-admin"
import authProvider from "./authProvider"
//import "whatwg-fetch"
type Options = {
    method?: "POST" | "GET" | "DELETE" | "PATCH",
    multipart?: boolean,
    body?: any,
    
    token?: string, 
}
export type Response = { status: number, headers: Headers, body: string, json: any }

export const fetchJson = (url: string, options: Options = {}): Promise<Response> => {
    const { token } = authProvider.getProfile() || options || {}
    const requestHeaders = new Headers({ Accept: "application/json" })
    if (!options.multipart) {
        requestHeaders.set("Content-Type", "application/json")
    }
    if (token) {
        requestHeaders.set("Authorization", `Token ${token}`)
    }
    return fetch(url, { ...options, headers: requestHeaders })
        .then(response => response.text().then(text => ({
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            body: text,
        })))
        .then(({ status, statusText, headers, body }) => {
            let json
            try {
                json = JSON.parse(body)
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                let error = json && json.error
                let message = statusText
                if (error) {
                    if (error.errors && error.errors.length > 0) error = error.errors[0]
                    if (error && error.message) message = error.message
                }
                return Promise.reject(new HttpError(message, status, body))
            }
            return { status, headers, body, json }
        })
}

export const queryParameters = (data: any) => Object.keys(data)
    .map(key => [key, Array.isArray(data[key]) ? data[key] : [data[key]]])
    .reduce((res, [key, value]) => res.concat(value.map((v: string) => [key, v])), [])
    .map(pair => pair.map(encodeURIComponent).join("="))
    .join("&")
