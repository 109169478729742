import React, { MutableRefObject } from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { sortBy, filter, get, each, chunk, size, keys } from 'lodash';
import { ListItemButton, ListSubheader, styled, Theme } from '@mui/material';
import { gpioPhysical } from '../../../../lib/gpio';
import { useState } from 'react';
import { Collapse } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import { DoubleArrow, ExpandMore, ExpandLess } from '@mui/icons-material';
import DeviceIcon from "./DeviceIcon"
import { useCallback } from 'react';
import { useNotify } from 'ra-core';
import { GpioState, OnPinClick, PinConfig } from '../../../../lib/types';
import { Socket } from 'socket.io-client';

// const useStyles = makeStyles(theme => ({
//   margin: {
//     margin: theme.spacing(2),
//   },
//   lowValue: {
//     color: '#fff',
//     backgroundColor: '#000000',
//   },
//   highValue: {
//     color: '#fff',
//     backgroundColor: "#FF0000",
//   },
//   nested: {
//     paddingLeft: theme.spacing(4),
//   },
// }))

interface DevicesProps {
  gpio: GpioState
  onPinClick: OnPinClick, 
  socketRef: MutableRefObject<Socket | undefined>,
  config: any  
}
interface PinListProps {
  label: string
  gpio: GpioState,
  pins: PinConfig[],
  onPinClick: OnPinClick, 
  socketRef: MutableRefObject<Socket | undefined>,
}
interface GpioPinProps {
  gpio: GpioState,
  config: PinConfig,
  onPinClick: OnPinClick,
  socketRef: MutableRefObject<Socket | undefined>,
}
interface GpioActionsProps {
  name: string
  expanded: boolean
  config: any
  onAction: (name: string, action: string) => void
}

const DeviceRoot = styled("div")({
  display: 'flex',
  flexDirection: "row",
  justifyContent: "space-evenly",
})


function getStateValue(value: number, config: any) {
  const state = get(config, "state.value", {})
  return state[value] || value || ""
}

function getActionValue(value: number, config: any) {
  const state: Record<number, string[]> = {}
  each(config.actions, (trigger, name) => {
    if(trigger.length === 1) {
      const val: number = trigger[0].value
      if (val != null) {
        state[val] = state[val] || []
        state[val].push(name)
      }
    }
  })
  if (state[value]) {
    return state[value].join("/")
  }
  return value || ""

}

function GpioActions({ name, expanded, config, onAction }: GpioActionsProps){  
  const actions = keys(config.actions)
  return <Collapse in={expanded} timeout="auto" unmountOnExit>
    <List component="div" disablePadding>
      {actions.map(action => <ListItemButton key={action} sx={(theme) => ({ paddingLeft: theme.spacing(4), })} onClick={() => onAction(name, action) }>
        <ListItemIcon> <DoubleArrow /></ListItemIcon>
        <ListItemText primary={action} />
      </ListItemButton>)}      
    </List>
  </Collapse>
}

function GpioPin({ config: {name, config}, gpio, onPinClick, socketRef }: GpioPinProps)  {
  const notify = useNotify()
  const [expanded, setExpanded] = useState(false)
  
  const onClick = (event: any) => {
    event.preventDefault()
    onPinClick(num, { name, config })
  }
  const onAction = useCallback((name: string, action: string) => {
    if (socketRef.current) {
      socketRef.current.emit("execAction", name, action)
      notify(`${name} ${action} has been sent!`, { type: "success" })
    }    
  }, [socketRef, notify])
  
  const num = gpioPhysical(config.pin)
  const { value } = gpio[num] ?? {}
  const displayValue = config.type === "output" ? getActionValue(value, config) : getStateValue(value, config)
  
  const hasActions = config.type === "output" && size(config.actions) > 0
  return <>
    <ListItemButton >
      <ListItemAvatar>
        <Badge color={value ? "error" : "primary"} badgeContent={config.pin} overlap={"circular"} >
          <Avatar onClick={onClick}><DeviceIcon name={name} /></Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={name} secondary={displayValue} onClick={() => setExpanded(!expanded)}/>
      {hasActions && (expanded ? <ExpandLess onClick={() => setExpanded(false)} /> : <ExpandMore onClick={() => setExpanded(true)} />)}
    </ListItemButton> 
    <GpioActions name={name} config={config} expanded={expanded} onAction={onAction}/>
  </>
}

function PinList({ label, pins, gpio, onPinClick, socketRef }: PinListProps) {
  return <List component="nav" subheader={<ListSubheader component="div"> {label} </ListSubheader>} dense sx={(theme: Theme) => ({
      backgroundColor: theme.palette.background.paper,
      marginInlineEnd: 2
    })}>
    {pins.map(pin => <GpioPin key={pin.name} config={pin} gpio={gpio} onPinClick={onPinClick} socketRef={socketRef} />)}
  </List>
}


export default function Devices({ gpio, config, onPinClick, socketRef }: DevicesProps) {
  const gpioInput = sortBy(filter(config.hardware, { driver: "gpio-driver", config: { type: "input"}}), "name")
  const gpioOutput = sortBy(filter(config.hardware, { driver: "gpio-driver", config: { type: "output" } }), "name")
  
  return <DeviceRoot >
    {chunk(gpioInput, 7).map((pins, index) => <PinList key={index} label="Input sensors" pins={pins} gpio={gpio} onPinClick={onPinClick} socketRef={socketRef}/>)}
    {chunk(gpioOutput, 7).map((pins, index) => <PinList key={index} label="Output sensors" pins={pins} gpio={gpio} onPinClick={onPinClick} socketRef={socketRef}/>)}
  </DeviceRoot>
}
