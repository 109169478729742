import React, { MutableRefObject, ReactNode } from 'react';
import './gpio.css';
import { useCallback } from 'react';
import { useNotify } from 'ra-core';
import { merge, fromPairs } from "lodash"
import { styled } from '@mui/material';
import { Socket } from 'socket.io-client';
import { GpioState } from '../../../../lib/types';

interface GpioProps {
  num: number
  className?: string, 
  children?: ReactNode, 
  label?: string, 
  alt?: string, 
  data: Record<number, { value: number, type: string, active: boolean }>,
  onClick: (num: number, value: number, type: string) => void
}
interface RpiBoardProps {
  gpio: GpioState,
  readonly?: boolean
  activePins: number[] 
  socketRef: MutableRefObject<Socket | undefined>
}

const GpioPinRoot = styled('li')({
  "& .high": {
    color: "#8e1717"
  },
  "& .low": {
    color: "#2b2727"
  },
  "& .out": {
    textDecoration: "underline"
  }
})

function Gpio({ num, className: pinClassName, children, label, alt, data, onClick }: GpioProps) {
  const { value, type, active } = data[num] ?? {}
  const className = value != null ? (value ? "high" : "low") : ""

  const onClickLink = useCallback((event: any) => {
    event.preventDefault()
    onClick(num, value, type)
  }, [onClick, num, value, type])

  return <GpioPinRoot className={`pin${num} ${pinClassName} ${active ? "active": ""}`}>
    <a href="/" title="" onClick={onClickLink}>
      <span className="default">
        <span className="phys">{num}</span>
        <span className={`name ${className} ${type === "OUT" ? "out" : ""}`}>{children || label}</span>&nbsp;
        {alt && <small className={className}>({alt})</small>}
      </span>
      <span className="pin"></span>
    </a>
  </GpioPinRoot>
}

export default function RpiBoard({ gpio, activePins = [], socketRef, readonly = false }: RpiBoardProps) {  
  const notify = useNotify()
  const data = merge({}, gpio, fromPairs(activePins.map((num: number) => [num, ({ ...gpio[num], active: true })])))
  
  const onPinWrite = useCallback((num: number, value: number, type: string) => {
    if (type === "OUT" && socketRef.current && !readonly) {
      socketRef.current.emit("gpioWrite", num, value ? 0 : 1)
      notify(`PIN${num} was sent value: ${value}`, { type: "success" })
    }    
  }, [socketRef, readonly, notify])
  
  return <nav id="gpio">
    <div id="pinbase"></div>
    <ul className="bottom">
      <Gpio num={1} data={data} onClick={onPinWrite} className="pow3v3" >3v3 Power</Gpio>
      <Gpio num={3} data={data} onClick={onPinWrite} className="gpio i2c" alt="I2C1 SDA">GPIO 2</Gpio>
      <Gpio num={5} data={data} onClick={onPinWrite} className="gpio i2c" alt="I2C1 SCL">GPIO 3</Gpio>
      <Gpio num={7} data={data} onClick={onPinWrite} className="gpio" alt="GPCLK0">GPIO 4</Gpio>
      <Gpio num={9} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>
      <Gpio num={11} data={data} onClick={onPinWrite} className="gpio" >GPIO 17</Gpio>
      <Gpio num={13} data={data} onClick={onPinWrite} className="gpio" >GPIO 27</Gpio>
      <Gpio num={15} data={data} onClick={onPinWrite} className="gpio" >GPIO 22</Gpio>
      <Gpio num={17} data={data} onClick={onPinWrite} className="pow3v3"  >3v3 Power</Gpio>

      <Gpio num={19} data={data} onClick={onPinWrite} className="gpio spi" alt="SPI0 MOSI">GPIO 10</Gpio>
      <Gpio num={21} data={data} onClick={onPinWrite} className="gpio spi" alt="SPI0 MISO">GPIO 9</Gpio>
      <Gpio num={23} data={data} onClick={onPinWrite} className="gpio spi" alt="SPI0 SCLK">GPIO 11</Gpio>
      <Gpio num={25} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>
      <Gpio num={27} data={data} onClick={onPinWrite} className="gpio i2c" alt="EEPROM SDA">GPIO 0</Gpio>

      <Gpio num={29} data={data} onClick={onPinWrite} className="gpio" >GPIO 5</Gpio>
      <Gpio num={31} data={data} onClick={onPinWrite} className="gpio" >GPIO 6</Gpio>
      <Gpio num={33} data={data} onClick={onPinWrite} className="gpio" alt="PWM1" >GPIO 13</Gpio>

      <Gpio num={35} data={data} onClick={onPinWrite} className="gpio pcm" alt="PCM FS" >GPIO 19</Gpio>
      <Gpio num={37} data={data} onClick={onPinWrite} className="gpio"  >GPIO 26</Gpio>
      <Gpio num={39} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>
    </ul>
    <ul className="top">
      <Gpio num={2} data={data} onClick={onPinWrite} className="pow5v"  >5v Power</Gpio>
      <Gpio num={4} data={data} onClick={onPinWrite} className="pow5v"  >5v Power</Gpio>
      <Gpio num={6} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>
      <Gpio num={8} data={data} onClick={onPinWrite} className="gpio uart" alt="UART TX" >GPIO 14</Gpio>
      <Gpio num={10} data={data} onClick={onPinWrite} className="gpio uart" alt="UART RX" >GPIO 15</Gpio>
      <Gpio num={12} data={data} onClick={onPinWrite} className="gpio pcm" alt="PCM CLK" >GPIO 18</Gpio>
      <Gpio num={14} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>
      <Gpio num={16} data={data} onClick={onPinWrite} className="gpio" >GPIO 23</Gpio>
      <Gpio num={18} data={data} onClick={onPinWrite} className="gpio" >GPIO 24</Gpio>
      <Gpio num={20} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>
      <Gpio num={22} data={data} onClick={onPinWrite} className="gpio" >GPIO 25</Gpio>
      <Gpio num={24} data={data} onClick={onPinWrite} className="gpio spi" alt="SPI0 CE0">GPIO 8</Gpio>
      <Gpio num={26} data={data} onClick={onPinWrite} className="gpio spi" alt="SPI0 CE1">GPIO 7</Gpio>
      <Gpio num={28} data={data} onClick={onPinWrite} className="gpio i2c" alt="EEPROM SCL">GPIO 1</Gpio>
      <Gpio num={30} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>

      <Gpio num={32} data={data} onClick={onPinWrite} className="gpio" alt="PWM0">GPIO 12</Gpio>
      <Gpio num={34} data={data} onClick={onPinWrite} className="gnd"  >Ground</Gpio>
      <Gpio num={36} data={data} onClick={onPinWrite} className="gpio" > GPIO 16</Gpio>

      <Gpio num={38} data={data} onClick={onPinWrite} className="gpio pcm" alt="PCM DIN">GPIO 20</Gpio>
      <Gpio num={40} data={data} onClick={onPinWrite} className="gpio pcm" alt="PCM DOUT">GPIO 21</Gpio>
    </ul>
  </nav>
}