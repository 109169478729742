import React, { ComponentType, ReactElement } from "react"
import {CustomRoutes, Resource, ResourceElement}  from "react-admin"
import { Route } from "react-router-dom"
import { checkPermissions } from "../lib/helper"

import * as Controllers from "../resources/Controller"
import * as Applications from "../resources/Application"
import * as Admins from "../resources/Admin"
import * as NamedScripts from "../resources/NamedScript"
import * as Packages from "../resources/Package"
import Terminal from "../resources/Debug/TerminalPage"
import MonitoringPage from "../resources/Debug/MonitoringPage"


type ResourceType = "list" | "create" | "edit" | "show"

type ResourceForParams = {
  rest: {
    list?: ComponentType<any> | ReactElement;
    create?: ComponentType<any> | ReactElement;
    edit?: ComponentType<any> | ReactElement;
    show?: ComponentType<any> | ReactElement;
  },
  permissions: string[]
  access: {
    list?: string[]
    create?: string[]
    edit?: string[]
    show?: string[]
  }
}
function resourceProps({ rest, permissions, access = {} }: ResourceForParams) {
  //console.log(permissions)
  const hasAccess = (type: ResourceType) => access[type] && permissions ? checkPermissions(permissions, ...(access[type] || [])) : true
  const resource = (type: ResourceType) => (rest[type] && hasAccess(type)) ? rest[type] : undefined
  return ({ 
      show: resource("show"),  
      list: resource("list"),
      edit: resource("edit"),
      create: resource("create") 
    })
}

export default function resources(permissions: any): ResourceElement[] {
  return permissions && [
    <Resource key={"controllers"} name="controllers"  {...resourceProps({
      rest: Controllers,
      permissions, access: {
        
      }
    })} />,
    <Resource key={"applications"} name="applications"  {...resourceProps({
      rest: Applications,
      permissions, access: {
        
      }
    })} />,      
    <Resource key={"admins"} name="admins"  {...resourceProps({
        rest: Admins, 
        permissions, access: {
        
        }
    })}/>,
    <Resource key="named-scripts" name="named-scripts"  {...resourceProps({
      rest: NamedScripts,
      permissions, access: {
   
      }
    })} />,
    <Resource key="packages" name="packages"  {...resourceProps({
      rest: Packages,
      permissions, access: {}
    })} />,
    <Resource name="controllers/connectivity" />,
    <Resource name="logs" />,
    <Resource name="scripts" />,
    <Resource name="packages/types" />,
    <Resource name="controller-packages" />,

    <CustomRoutes noLayout>
      <Route key="ssh" path="/ssh/:uuid" element={<Terminal />} />,
      <Route key="monitoring" path="/monitoring/:uuid/:token" element={<MonitoringPage />} />,
    </CustomRoutes>,
    <CustomRoutes>
      <Route key="firmware" path="/firmware" element={<Packages.Firmware />} />,
      <Route key="profile" path="/profile" element={<Admins.Profile resource="admins" basePath="/admins" />} />,
    </CustomRoutes>
  ]  
}
