import React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import {chunk} from 'lodash';
import { useCallback } from 'react';
import { ListItemButton, Theme } from '@mui/material';
import { OnPinClick } from '../../../../lib/types';

interface GpioViewProps {
  name: string
  physical:  number, 
  onPinClick: OnPinClick
}
interface GpioStatePlaceholderProps {
  onPinClick: OnPinClick
}
function GpioView({ name, physical, onPinClick }: GpioViewProps) {
  const onClick = useCallback(() => onPinClick(physical, { name, config: {} }), [physical, name, onPinClick])
  return <ListItemButton  onClick={onClick}>  
    <ListItemAvatar> <Avatar >{physical}</Avatar></ListItemAvatar>
    <ListItemText primary={name} />
  </ListItemButton>
}

const PINMAP_40 = [
  -1,
  -1, -1,		/*  P1  P2 */
   2, -1,		/*  P3  P4 */
   3, -1,		/*  P5  P6 */
   4, 14,		/*  P7  P8 */
  -1, 15,		/*  P9  P10 */
  17, 18,		/* P11  P12 */
  27, -1,		/* P13  P14 */
  22, 23,		/* P15  P16 */
  -1, 24,		/* P17  P18 */
  10, -1,		/* P19  P20 */
   9, 25,		/* P21  P22 */
  11,  8,		/* P23  P24 */
  -1,  7,		/* P25  P26 */
   0,  1,		/* P27  P28 */
   5, -1,		/* P29  P30 */
   6, 12,		/* P31  P32 */
  13, -1,		/* P33  P34 */
  19, 16,		/* P35  P36 */
  26, 20,		/* P37  P38 */
  -1, 21		/* P39  P40 */
]
type BcmMapElement = { bcm: number, physical: number }
const BCM_MAP: BcmMapElement[] = PINMAP_40.reduce<BcmMapElement[]>((acc, bcm, physical) => {
  if(bcm > 1 ) acc.push({bcm, physical})
  return acc
}, [])

export default function GpioStatePlaceholder({ onPinClick }: GpioStatePlaceholderProps) {  
  const rows = chunk(BCM_MAP, 7)
  const renderRow = (row: BcmMapElement[]) =>
    <List dense sx={(theme: Theme) => ({minWidth: 120, backgroundColor: theme.palette.background.paper})}>
      {row.map(({bcm, physical}) => {
        return <GpioView key={`BCM ${bcm}`} name={`BCM ${bcm}`} physical={physical} onPinClick={onPinClick} />
      })}
    </List>
  return <Grid container direction="row">
    <div >
      {renderRow(rows[0])}
    </div>
    <div  >
      {renderRow(rows[1])}
    </div>
    <div  >
      {renderRow(rows[2])}
    </div>
    <div  >
      {renderRow(rows[3])}
    </div>
  </Grid>
}
