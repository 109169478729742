import React, { JSXElementConstructor } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';

import DoorIcon from '@mui/icons-material/MeetingRoom';
import LockIcon from '@mui/icons-material/Lock';
import AlarmIcon from '@mui/icons-material/Security';
import LedIcon from '@mui/icons-material/WbSunny';
import DefaultIcon from '@mui/icons-material/Subject';
import {map} from 'lodash';
import { DeviceGlobalState } from '../../../../lib/types';
import { styled, SvgIconProps } from '@mui/material';

const ListStyled = styled(List)(({theme}) => ({
  unstable_sx: {
    width: '100%',
    maxWidth: "50%",
    backgroundColor: theme.palette.background.paper,
  }  
}))

const ICONS: Record<string, JSXElementConstructor<SvgIconProps>> = {
  door: DoorIcon,
  relay: LockIcon,
  alarm: AlarmIcon,
  led: LedIcon,
  default: DefaultIcon
}

interface StateViewProps {
  name: string
  value: string
}
function StateView({ name, value }: StateViewProps) {
  let type = "default"
  if(name.match(/door/)) type = "door"
  if(name.match(/relay/)) type = "relay"
  if(name.match(/alarm/)) type = "door"
  if(name.match(/led/)) type = "led"
  return <ListItem dense>
    <ListItemAvatar><Avatar>{React.createElement(ICONS[type], {}, [])}</Avatar></ListItemAvatar>
    <ListItemText primary={name} secondary={<span>{value.split(",").join("\n")}</span>} />
  </ListItem>
}

interface GlobalStateProps {
  state: DeviceGlobalState
}

export default function GlobalState({ state }: GlobalStateProps){
  return <ListStyled dense>
    {map(state, (value, name) =>
      <StateView key={name} name={name} value={value} />
    )}
  </ListStyled>
}
