import React, { useEffect }from 'react';
import { map, find, trim, uniq, xor } from "lodash"
import { ArrayInput, TextInput, SimpleFormIterator, CommonInputProps } from 'ra-ui-materialui';
import { useFormContext } from 'react-hook-form';

const generateParams = (content: string, params: any) => {
  const interpolate = /{{([\s\S]+?)}}/g
  const contentArgs = (content || "").match(interpolate)

  const args = map(contentArgs, v => trim(v, "{} ").replace(/^params./, ""))
  return map(uniq(args), name => find(params, ["name", name]) || ({ name }))
}

export default function ParamsInput(props: CommonInputProps) {
  const {watch, setValue} = useFormContext()
  const content = watch("content");
  const params = watch("params");

  useEffect(() => {
    const _params = generateParams(content, params)
    if (xor(_params, params).length > 0) {
      setValue("params", _params)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, setValue])

  return <ArrayInput source="params" defaultValue={[]}>
    <SimpleFormIterator disableRemove disableAdd  >
      <TextInput disabled source="name" label="Name" />
      <TextInput source="description" label="Description"  />
      <TextInput source="default" label="Default" />
    </SimpleFormIterator>
  </ArrayInput>
}
