const timeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false }

const channels = [
  { "id": "stable", "name": "stable" },
  { "id": "beta", "name": "beta" },
  { "id": "build", "name": "build" },
  { "id": "system", "name": "system" }
]

const platforms = [
  { id: "tinycore14", name: "tinycore14" },
  { id: "tinycore13", name: "tinycore13" },
  { id: "tinycore9", name: "tinycore9" },
  { id: "tinycore9.1", name: "tinycore9.1" },
  { id: "rpi-legacy", name: "rpi-legacy" },
];
const roles = [
  { "id": "root", "name": "Super Admin" },
  { "id": "admin", "name": "Admin" },
  { "id": "viewer", "name": "Viewer" },  
]
const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
export {
  timeOptions,
  channels,
  platforms,
  dateOptions,
  roles
}