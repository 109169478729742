import React, {  ReactNode, RefObject } from "react"
import { EditButton, RefreshButton, ListButton, ShowButton, DeleteButton, CreateButton, TopToolbar } from "react-admin"
import PropTypes from "prop-types"

const cardActionStyle = {
    display: "flex",
    "justifyContent": "flex-end",
    "flexWrap": "wrap"
}
type DefaultActionsProps = {
    resource?: string
    hasEdit?: boolean
    hasList?: boolean
    hasCreate?: boolean 
    hasDelete?: boolean
    hasShow?: boolean
    noRefresh?: boolean
    readOnly?: boolean

    children?: ReactNode
    data?: any, 
    portalRef?: RefObject<HTMLDivElement | undefined>
}
const DefaultActions = ({ hasEdit, hasList, hasCreate, hasDelete,
    children, resource, data, hasShow, noRefresh, readOnly, portalRef }: DefaultActionsProps) => {

    if (readOnly) {
        hasEdit = false
        hasCreate = false
        hasDelete = false
    }
    if (data === undefined) {
        hasShow = false
        hasDelete = false
        hasEdit = false
    }
    return (
        <TopToolbar sx={cardActionStyle}  >
            <div ref={portalRef as RefObject<HTMLDivElement>} />
            {children}
            {hasList && <ListButton resource={resource} />}
            {hasShow && <ShowButton resource={resource} record={data} />}
            {hasEdit && <EditButton resource={resource} record={data} />}
            {hasCreate && <CreateButton resource={resource} />}
            {!noRefresh && <RefreshButton resource={resource} />}
            {hasDelete && <DeleteButton resource={resource} record={data} />}
        </TopToolbar>
    )
}

DefaultActions.propTypes = {
    basePath: PropTypes.string,
    resource: PropTypes.string,
    hasEdit: PropTypes.bool,
    hasList: PropTypes.bool,
    hasCreate: PropTypes.bool,
    hasDelete: PropTypes.bool,
    data: PropTypes.object,
    children: PropTypes.node,
}
export default DefaultActions
