import { List, ListItemButton, Theme } from "@mui/material";
import { ListItemText } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { MutableRefObject, ReactElement, useState } from "react";
import { ExpandLess } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { ListItemIcon } from "@mui/material";
import { ListSubheader } from "@mui/material";
import { useCallback } from "react";
import { Memory } from "@mui/icons-material";
import { Contactless } from "@mui/icons-material";
import { Apps } from "@mui/icons-material";
import { LockOpen } from "@mui/icons-material";
import { includes } from "lodash"
import { Lock } from "@mui/icons-material";
import { useEffect } from "react";
import { MonitoringConfigRestriction } from "../../../../../lib/types";
import { Socket } from "socket.io-client";

type MonitoringConfigBlockListItem = {
  name: string,
  type: string
}
interface RestrictionsProps {
  restrictions?: MonitoringConfigRestriction,
  socketRef: MutableRefObject<Socket | undefined>,
  readonly: boolean
}
interface BlockListProps {
  socketRef: MutableRefObject<Socket | undefined>,
  name: string, 
  icon: ReactElement, 
  items: MonitoringConfigBlockListItem[], 
  blocked: string[]
  readonly: boolean
}
interface BlockListItemProps {
  item: MonitoringConfigBlockListItem, 
  blocked: string[], 
  socketRef: MutableRefObject<Socket | undefined>,
  readonly: boolean
}
function useToogle(defaultOpen: boolean): [boolean, () => void] {
  const [open, setOpen] = useState(defaultOpen)
  const toggle = useCallback(() => { setOpen(!open) }, [open, setOpen])
  return [open, toggle]
}

function BlockListItem({ item, blocked, socketRef, readonly }: BlockListItemProps) {
  const isBlocked = includes(blocked, item.name)
  const [isBlockedUI, setIsBlocked] = useState<boolean>(false)
  
  useEffect(() => {
    setIsBlocked(isBlocked)
  }, [isBlocked, setIsBlocked])
  
  const onClick = useCallback((e: any) => {
    if (!readonly) {
      setIsBlocked(!isBlocked)
      if (socketRef.current)
        socketRef.current.emit("restriction", !isBlocked, { type: item.type, name: item.name })
    }    
  }, [isBlocked, item.type, item.name, socketRef, readonly])

  return <ListItemButton sx={(theme: Theme) => ({ paddingLeft:  theme.spacing(4)})} onClick={onClick} >
    <ListItemIcon >  {isBlockedUI ? <Lock color="error"/> : <LockOpen color="primary" />}</ListItemIcon>
    <ListItemText primary={item.name} />
  </ListItemButton>
}

function BlockList({ name, icon, items = [], blocked = [], socketRef, readonly }: BlockListProps) {
  const [open, toggle] = useToogle(false);
  return <>
    <ListItemButton onClick={toggle}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {items.map(item => <BlockListItem item={item} key={item.name} blocked={blocked} socketRef={socketRef} readonly={readonly}/>)}
      </List>  
    </Collapse>
  </>
}

type RestrictionSources = {
  devices: Record<string, any>,
  actions: string[],
  routes: string[],
}
export default function Restrictions({ restrictions, socketRef, readonly }: RestrictionsProps) {
  const sources: RestrictionSources = restrictions?.sources || {
    devices: [],
    actions: [],
    routes: []
  }
  const block: Record<string, string[]> = restrictions?.current || {
    device: [],
    action: [],
    route: []
  }

  return (
    <List subheader={<ListSubheader component="div">Blocklist</ListSubheader>} sx={{
        marginRight: 1,
        minWidth: 300,
        border: "1px solid #CCC"
      }}>
      <BlockList name="Devices" icon={<Memory />} items={sources.devices.map((device: any) => ({ ...device, type: "device" }))} blocked={block.device} socketRef={socketRef} readonly={readonly}/>
      <BlockList name="Actions" icon={<Apps />} items={sources.actions.map(action => ({ name: action, type: "action" }))} blocked={block.action} socketRef={socketRef} readonly={readonly} />
      <BlockList name="Routes" icon={<Contactless />} items={sources.routes.map(route => ({ name: route, type: "route" }))} blocked={block.route} socketRef={socketRef} readonly={readonly} />
    </List>
  );


}
