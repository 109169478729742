// in src/posts.js
import React from 'react';
import { ArrayInput, FormTab, SelectInput, SimpleFormIterator, TabbedForm, TextInput } from 'ra-ui-materialui';
import { FormDataConsumer, Identifier, usePermissions } from 'react-admin';
import {  isSuperAdmin } from '../../../lib/helper';
import { roles } from '../../../lib/consts';
import { passwordConfirmValidate } from '../../../lib/validations';
import { useAccount, useFilteredApplications } from '../../../hooks';

const inlineIteratorSx = {
  "& .RaSimpleFormIterator-form": {
    display: "flex",
    flexDirection: "row",
    "& .ra-input": {
      marginRight: 10,
      display: "flex",
    },
  }
}
export default function AdminForm({id}: {id?: Identifier | null}) {
  const { permissions } = usePermissions()
  const filteredRoles = isSuperAdmin(permissions) ? roles : roles.filter(role => role.id !== 'root')
  const filteredApps = useFilteredApplications("viewer")
  const account = useAccount()
  const rolesDisabled = account.id === id
  return <TabbedForm sx={{
    "& .MuiInputBase-root": {
      minWidth: "250px"
    }
  }}>
    <FormTab label="Summary"  >
      <TextInput disabled={!!id} source="email" />
      <TextInput source="name" isRequired={true} />
    </FormTab>
    <FormTab label="Password"  >
      <TextInput source="password" autoComplete="off" type="password" />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
    </FormTab>
    <FormTab label="Roles"  >
      <ArrayInput source="roles" defaultValue={[]} disabled={rolesDisabled}>
        <SimpleFormIterator sx={inlineIteratorSx} >
          <SelectInput source="role" label="Role" choices={filteredRoles} defaultValue="root" />
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <SelectInput disabled={rolesDisabled || scopedFormData.role === "root"} source={getSource!("ApplicationUuid")} label="ApplicationUuid" optionText={"description"} optionValue="uuid" choices={filteredApps} />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  </TabbedForm>
}
