import React, {useCallback} from "react"
import { useNotify, useRecordContext, useResourceContext, useUpdate} from "ra-core"
import {  FormControlLabel, Checkbox } from "@mui/material"
import { useStateToggle } from "../hooks"


interface Props {
  source: string,
  onSuccess?: () => void
}
export default function BooleanSwitch({ source, onSuccess: onSuccessProp }: Props) {
  const record = useRecordContext()
  const resource = useResourceContext()
  const [checked, toggle] = useStateToggle(record[source])
  const notify = useNotify()
  const onSuccess = useCallback(() => {
    notify("Updated", { type: "success" })
    onSuccessProp && onSuccessProp()
  }, [notify, onSuccessProp])  
  const [update, ] = useUpdate(resource, { id: record.id}, { mutationMode: "pessimistic", onSuccess });

  const onChange = useCallback(() => {
    toggle()
    update(resource, { data: { [source]: !checked } })
  }, [toggle, resource, source, checked, update ])

  return <FormControlLabel label="" control={<Checkbox checked={checked} onChange={onChange} />} />
}
