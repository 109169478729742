import React, { useState } from "react"
import {Datagrid, TextField, FunctionField, EditButton} from "ra-ui-materialui"
import { ArrayField, Button, SingleFieldList, DateField, ChipField } from "ra-ui-materialui"
import {values} from "lodash"
import RunDialog from "./components/RunDialog"
import IconSend from '@mui/icons-material/Send';
import { RaRecord, useRecordContext } from "react-admin"
import { List } from "../../components/DefaultDetails"

interface RunButtonProps {
  onClick: (record: RaRecord) => void;
}
function RunButton({ onClick }: RunButtonProps) {
  const record = useRecordContext()
  return <Button onClick={() => onClick(record)} label="Run" >
    <IconSend />
  </Button>
}
  
export default function NamedScriptsList() {
  const [record, setRecord] = useState<RaRecord | null>(null)
  
  return <>
    <RunDialog open={Boolean(record)} record={record} onClose={() => setRecord(null)} />
    <List  >
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" />
        <FunctionField source="params" render={(record: RaRecord) =>
          <ArrayField source="values" record={{ values: values(record.params) }}>
            <SingleFieldList>
              <ChipField source="description" />
            </SingleFieldList>
          </ArrayField>
        } />
        <TextField source="priority" />
        <DateField source="createdAt" />
        <EditButton />
        <RunButton onClick={setRecord} />
      </Datagrid>
    </List>
  </>
}
