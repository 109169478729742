import React from "react"
import { TextField, TextFieldProps } from "ra-ui-materialui"
import storage from "../../../lib/storage"
import {get } from "lodash"
import { useRecordContext } from "react-admin"

interface ApplicationReferenceFieldProps extends TextFieldProps {
  source: string,
} 
const ApplicationReferenceField = ({ source = "ApplicationUuid", ...props }: ApplicationReferenceFieldProps) => {
  const applications = storage.getObject("applications")
  const record = useRecordContext()
  const app = applications[get(record, source)] 
  return <TextField source="description" record={app} label="Application" {...props} />
}
export default ApplicationReferenceField