import { isArray, omitBy } from "lodash"
import { Dispatch, SetStateAction, useCallback, useState } from "react"
import EventEmmitter from "./EventEmitter"

class Storage extends EventEmmitter {
  getString(name: string) {
    return localStorage.getItem(name)
  }
  setString(name: string, value: string) {
    localStorage.setItem(name, value)
    this.emit(name, value)
    return value
  }
  getObject(name: string) {
    const str = localStorage.getItem(name)
    return str ? JSON.parse(str) : undefined
  }
  setObject(name: string, value: any) {
    localStorage.setItem(name, JSON.stringify(value))
    this.emit(name, value)
    return value
  }
  unset(name: string) {
    localStorage.removeItem(name)
    this.emit(name, undefined)
  }
}

const storage = new Storage()
export default storage

type Cache = Record<string, {data: any, expiredAt: number}>
type DefaultFn<S> = (() => S[]) | S[]

function updateCacheStorage(scope: string, cache: Cache, now: number) {
  const cacheFiltered = omitBy(cache, (entry, k) => entry.expiredAt < now)
  storage.setObject(scope, cacheFiltered)
}
export function setCacheValue<S>(scope: string, key: string, value: S, ttl = 900000, limit = 200) {
  const now = new Date().valueOf()
  const cache: Cache = storage.getObject(scope) || {}
  cache[key] = {
    data: isArray(value) ? value.slice(0, limit) : value,
    expiredAt: now + ttl
  }
  updateCacheStorage(scope, cache, now)
}
export function getCacheValue<S>(scope: string, key: string, defaultFn: DefaultFn<S>, ttl = 900000, limit = 200): S | undefined {
  const now = new Date().valueOf()
  const cache = storage.getObject(scope) || {}
  const entry = cache[key]
  if (entry && entry.expiredAt > now) {
    cache[key] = {
      data: entry.data,
      expiredAt: now + ttl
    }
    updateCacheStorage(scope, cache, now)
    return cache[key].data
  } else if (defaultFn) {
    const value: any = typeof defaultFn == "function" ? defaultFn() : defaultFn
    setCacheValue(scope, key, value, ttl, limit)
    return value
  } else {
    return undefined
  }
}

export function useScopedStorageState<S>(scope: string, key: string, defaultFn: DefaultFn<S>, ttl = 900000, limit = 200): [S, Dispatch<SetStateAction<S>>] {
  const [value, _setValue] = useState<S | undefined>(getCacheValue(scope, key, defaultFn, ttl))
  const setValue = useCallback((val: any) => {
    setCacheValue(scope, key, val, ttl, limit)
    _setValue(val)
  }, [scope, key, _setValue, ttl, limit])
  return [value as S, setValue]

}
