import React from 'react';
import QuickCreateButton from "../../../components/QuickCreateButton"
import { SimpleForm, TextInput, SelectInput} from "ra-ui-materialui"
import { channels, platforms } from '../../../lib/consts';
import { makeStyles } from '../../../lib/makeStyles';

const useStyles = makeStyles(() => ({
  form: {
    "& > div": {
      display: "flex",
      flexWrap: "wrap",      
    },
    "& .ra-input": {
      flex: "50%"
    },
    "& .ra-input-url, & .ra-input-platform": {
      flex: "100%"
    }
  }
}))
export default function CreatePackageButton() {
  const classes = useStyles()
  return <QuickCreateButton label="Create Package" resource="packages"  >
    <SimpleForm toolbar={undefined} resource="packages" sx={classes.form}>
      <SelectInput label="Platform" source="platform" choices={platforms} isRequired={true}/>
      <TextInput source="name" isRequired={true}/>
      <TextInput label="Version" source="version" isRequired={true}/>
      <TextInput label="Branch" source="sourceBranch" isRequired={true}/>
      <SelectInput label="Channel" source="channel" choices={channels} isRequired={true}/>
      <TextInput label="Url" source="url" fullWidth isRequired={true}/>
      <TextInput multiline fullWidth label="Release notes" source="notes" rows={4} />
    </SimpleForm>
  </QuickCreateButton>
}