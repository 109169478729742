import { merge, includes, fromPairs } from "lodash"
import englishMessages from "ra-language-english"
import domainMessagesEn from "../i18n/en-US"
import { I18nProvider, resolveBrowserLocale } from "react-admin"
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { parse } from "query-string"

type Locale = "en-US"

const messages: Record<Locale, any> = {
  "en-US": merge({}, englishMessages, domainMessagesEn),
}
const locales: Locale[] = ["en-US"]
const fallBackLocales: Record<string, Locale> = {
  uk: "en-US"
}
function getUrlLocale(): string | null {
  try {
    return parse(document.location.search).locale as string
  } catch {
    return null
  }
}
function normalizeLocale(locale: string): Locale {
  const lang = (locale: string): string => locale.split("-")[0]

  if (includes(locales, locale)) {
    return locale as Locale
  }
  const extendedLocales = {
    ...fallBackLocales,
    ...fromPairs(locales.map(locale => [lang(locale), locale]))
  }
  const fallbackLocale: Locale | null = extendedLocales[lang(locale)]
  return fallbackLocale || locales[0]
}
export function getDefaultLocale() {
  let locale = getUrlLocale() || localStorage.getItem("locale") || resolveBrowserLocale()
  return normalizeLocale(locale)
}
const i18nProvider: I18nProvider = polyglotI18nProvider(locale => messages[normalizeLocale(locale)], getDefaultLocale(), {
  allowMissing: true,
  onMissingKey: (key: string) => key
})
i18nProvider.getLocales = () => locales.map(locale => ({ locale, name: locale }) )
i18nProvider.getDefaultLocale = getDefaultLocale
export default i18nProvider
