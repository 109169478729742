import React from 'react';
import IconConnected from '@mui/icons-material/SignalCellular4Bar'
import IconDisconnected from '@mui/icons-material/SignalCellularOff'
import IconLoading from '@mui/icons-material/SignalCellularNull'
import { useRecordContext } from "react-admin"

function isOnline(lastAccessedAt: Date | string): boolean {
  return (new Date(lastAccessedAt) > new Date(new Date().valueOf() - 5 * 60 * 1000))
}

export default function ConnectivityIconWrapper(props: any) {
  const record = useRecordContext()
  if (!record)
    return <IconLoading color="primary" />
  return isOnline(record.lastAccessedAt) ? <IconConnected color="primary" /> : <IconDisconnected color="disabled" />
}